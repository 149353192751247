import PropTypes from 'prop-types';
import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { Dropdown, DropdownToggle, DropdownMenu, Row, Col, Offcanvas, OffcanvasHeader, OffcanvasBody } from "reactstrap";
import SimpleBar from "simplebar-react";
import { Avatar, Button, Empty, Input, message, Popconfirm, Tooltip } from 'antd';

//Import images
import avatar3 from "../../../assets/images/users/avatar-3.jpg";
import avatar4 from "../../../assets/images/users/avatar-4.jpg";
// users
import user1 from "../../../assets/images/users/avatar-1.jpg";


//i18n
import { withTranslation } from "react-i18next";
import { connect } from 'react-redux';
import { fetchNewLocations, saveNewLocation } from '../../../helpers/backend_helper';
import AddPost from '../../../pages/Locations/Component/AddPost';
import { SearchOutlined } from '@ant-design/icons';

export function fetchAllLocations(locations = [], token) {
  return new Promise(async (resolve, reject) => {
    try {
      const apiResponse = await fetchNewLocations(50, token);
      const data = apiResponse.apiResponse;
      locations.push(...data.locations);
      if (data.nextPageToken) {
        locations = await fetchAllLocations(locations, data.nextPageToken)
      }
      return resolve(locations)
    } catch (err) {
      reject(err)
    }
  })
}



const submbitLocation = function (loc) {
  return new Promise(async (resolve, reject) => {
    try {
      await saveNewLocation(loc.name)
      message.success('Brand Added')
      setTimeout(() => {
        window.location.reload();
        resolve(true)
      }, 2000)
    } catch (err) {
      reject(err)
    }
  })
}

const handleLinkToRegularExpression = function (id) {
  const PATH = this.props.history?.location?.pathname || "";
  const regExp = /^\/(.*)\/(.*)$/
  // const regExp = /^\/d+\/(.*)$/;
  const match = PATH.match(regExp);
  if (match) {
    // const route = match[2];
    const route = PATH.replace(/^\/(\d*)\//g, "");

    return `/${id}/${route}`;
  }
  return `/${id}/${"dashboard"}`;
}

const navigationSelected = function (name) {
  const PATH = this.props.history?.location?.pathname || "";
  const regExp = /\/(.*)\/(.*)/
  const regExp2 = new RegExp(`\/(.*)\/${name}(.*)`, "g");

  const match = PATH.match(regExp2);
  if (match) {
    return true
  }
  return false;
}


class HeaderLocations extends Component {
  constructor(props) {
    super(props)
    this.state = {
      menu: false,
      isRight: false,
      isFetchLocationLoading: true,
      // fetchLocations: [],
      fetchLocations: [],

      inputValue: "",
      filteredList: [],
    }
    this.toggle = this.toggle.bind(this)
    this.toggleRightCanvas = this.toggleRightCanvas.bind(this)
    this.handleLinkToRegularExpression = handleLinkToRegularExpression.bind(this);
    this.navigationSelected = navigationSelected.bind(this);
  }

  toggle() {
    this.setState(prevState => ({
      menu: !prevState.menu,
    }))
  }

  async toggleRightCanvas() {
    this.setState({ isRight: !this.state.isRight });

    if (!this.state.fetchLocations.length) {
      this.setState({ isFetchLocationLoading: true })
      let data = await fetchAllLocations.bind(this)();
      this.setState({
        fetchLocations: data
      })
      this.setState({ isFetchLocationLoading: false })
    }
  }

  convertToUppercase(str) {
    return str.slice(0, 2).toUpperCase();
  }
  sliceHeader(str) {
    if (str.length <= 18) return str;
    return str.slice(0, 15) + '...';
  }


  componentDidMount() {
    this.filterList();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.fetchLocations !== this.state.fetchLocations) {
      this.filterList();
    }
  }


  filterList = () => {
    const list = this.state?.fetchLocations || [];
    const { inputValue } = this.state;
    const filtered = list.filter((item) =>
      item?.title.toLowerCase().includes(inputValue.toLowerCase())
    );
    this.setState({ filteredList: filtered });
  };

  handleInputChange = (e) => {
    const { value } = e.target;
    this.setState({ inputValue: value }, () => {
      this.filterList();
    });
  };


  render() {
    const savedLocations = this.props.locations?.map(i => i.name);

    const { inputValue, filteredList } = this.state;

    return (
      <React.Fragment>
        {/* <AddPost></AddPost> */}
        <Offcanvas
          isOpen={this.state.isRight}
          direction="end"
          toggle={this.toggleRightCanvas}
        >
          <OffcanvasHeader toggle={this.toggleRightCanvas}>
            Add Brand
          </OffcanvasHeader>
          <OffcanvasBody>
            <SimpleBar>
              {this.state.isFetchLocationLoading ? (
                <div className='d-flex justify-content-center'>
                  <div
                    className="spinner-border spinner-sm text-dark m-1"
                    role="status"
                  >
                    <span className="sr-only">Loading...</span>
                  </div>
                </div>
              ) : (
                <Input
                className='mb-4'
                  placeholder="Search"
                  value={inputValue} onChange={this.handleInputChange}
                  prefix={<SearchOutlined className="site-form-item-icon" />}
                />
              )}
              {filteredList
                ?.filter(loc => !savedLocations?.includes(loc.name))
                ?.map(loc => (
                  <React.Fragment key={loc.name}>

                    <div className="text-reset notification-item">
                      <div className="d-flex align-items-center">
                        {/* <img
                        src={avatar3}
                        className="me-3 rounded-circle avatar-xs"
                        alt="user-pic"
                      /> */}
                        <Avatar style={{ backgroundColor: "gray", verticalAlign: 'middle', minWidth: '40px' }} size="large">
                          {this.convertToUppercase(loc.title)}
                        </Avatar>
                        <div className="flex-grow-1" style={{
                          paddingLeft: "10px",
                          display: "flex",
                          alignItems: "center"
                        }}>
                          <h6 className="mt-0 mb-1">{loc.title}</h6>
                          <p className="flex-grow-1 d-flex" style={{
                            justifyContent: 'end',
                            alignItems: 'center',
                            marginBottom: "0px",
                            fontSize: '18px'
                          }}>
                            <Popconfirm
                              title="New Brand Add"
                              style={{
                                zIndex: 99999
                              }}
                              description="Are you sure about ?"
                              onConfirm={() => submbitLocation(loc)}
                            // onOpenChange={() => console.log('open change')}
                            >
                              <Tooltip placement="top" title={'Add Brand'}>
                                <i className="mdi mdi-plus" style={{
                                  border: '1px solid black',
                                  cursor: "pointer",
                                  width: '30px',
                                  height: '30px',
                                  display: "flex",
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                  borderRadius: "50%"
                                }} />
                              </Tooltip>
                            </Popconfirm>
                          </p>
                        </div>
                      </div>
                    </div>
                  </ React.Fragment>
                ))}

                {filteredList.length == 0 && this.state.isFetchLocationLoading == false && (
                  <Empty />
                )}
            </SimpleBar>
          </OffcanvasBody>
        </Offcanvas>
        <Dropdown
          isOpen={this.state.menu}
          toggle={this.toggle}
          className="dropdown d-inline-block"
          tag="li"
        >
          <DropdownToggle
            className="btn header-item"
            tag="button"
            id="page-header-notifications-dropdown"
          >

            <Avatar style={{ backgroundColor: "gray", verticalAlign: 'middle', }}>
              {this.convertToUppercase(this.props.selectedLocation.title || '+')}
            </Avatar>
            {/* <img
              className="rounded-circle header-profile-user"
              src={user1}
              alt="Header Avatar"
            />{" "} */}
            <span className="d-xl-inline-block ms-1">
              {this.sliceHeader(this.props.selectedLocation.title || 'Add Brand')}
            </span>
            <i className="mdi mdi-chevron-down d-none d-xl-inline-block" />
          </DropdownToggle>

          <DropdownMenu className="dropdown-menu dropdown-menu-lg dropdown-menu-center p-0">
            <div className="p-3">
              <Row className="align-items-center">
                <Col>
                  <h6 className="m-0"> All brands </h6>
                </Col>
              </Row>
            </div>

            <SimpleBar style={{ maxHeight: "300px" }}>
              {this.props.locations.map(loc => (
                <React.Fragment key={loc.id}>
                  <Link to={this.handleLinkToRegularExpression(loc.id)} className="text-reset notification-item" onClick={this.toggle}>
                    <div className="d-flex">
                      {/* <img
                        src={avatar3}
                        className="me-3 rounded-circle avatar-xs"
                        alt="user-pic"
                      /> */}
                      <Avatar style={{ backgroundColor: "gray", verticalAlign: 'middle', minWidth: '40px' }} size="large">
                        {this.convertToUppercase(loc.title)}
                      </Avatar>
                      <div className="flex-grow-1" style={{
                        paddingLeft: "10px",
                        display: "flex",
                        alignItems: "center"
                      }}>
                        <h6 className="mt-0 mb-1">{loc.title}</h6>
                        {/* <div className="font-size-12 text-muted">
                          <p className="mb-1">
                            {this.props.t("It will seem like simplified English") +
                              "."}
                          </p>
                          <p className="mb-0">
                            <i className="mdi mdi-clock-outline" />
                            {this.props.t("1 hours ago")}{" "}
                          </p>
                        </div> */}
                      </div>
                    </div>
                  </Link>
                </ React.Fragment>
              ))}
            </SimpleBar>
            <div className="p-2 border-top d-grid">
              <Link className="btn btn-sm btn-link font-size-14 text-center" to="#" onClick={this.toggleRightCanvas}>
                <i className="mdi mdi-plus me-1"></i> <span key="t-view-more">Add Brand</span>
              </Link>
            </div>
          </DropdownMenu>
        </Dropdown>
      </React.Fragment >
    )
  }
}

HeaderLocations.propTypes = {
  t: PropTypes.any
}

const mapStateToProps = ({ Location }) => {
  return ({
    locations: Location.locations,
    isLoading: Location.isLoading,
    selectedLocation: Location.selectedLocation,
    isLocationSelected: Location.isSelectedLocation
  })
};

const mapDispatchToProps = dispatch => ({
  getLocations: () => dispatch(getLocations()),
});



export default
  withRouter(withTranslation()(connect(
    mapStateToProps,
    mapDispatchToProps
  )(HeaderLocations)))
