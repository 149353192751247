import React, { Component, useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Container,
  Card,
  CardBody,
  Col,
  Row,
  Modal,
  Button,
  ModalHeader,
  ModalBody,
  Label,
} from "reactstrap";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { withRouter } from "react-router";


//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

//i18n
import { withTranslation } from "react-i18next";
// import InfiniteScroll from 'react-infinite-scroll-component';
import InfiniteScroll from 'react-infinite-scroller';

import classNames from "classnames";

// import images
import img1 from "../../assets/images/small/img-2.jpg"
import avtar1 from "../../assets/images/users/avatar-2.jpg"

// Import FecthAPI
import PaginateTable from "../../components/UI/Table";
import service from "../../helpers/Api/FetchInterceptor";
import Lightbox from "react-image-lightbox";
import { deleteNotification, getNotifications, selectLocation } from "../../store/actions";
import { Popconfirm, Tag } from "antd";
import moment from "moment";
import withRerender from "../../hooks/render";
import RatingTooltip from "react-rating-tooltip"
import { fetchLocationReview } from "../../helpers/backend_helper";


class Reviews extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPage: 1,
      pageSize: 20,
      locationId: 0,
      nextPageToken: "",
      lastPage: false,
      items: []
    };
  }

  async componentDidMount() {
    console.log("===============MOUNT===============")

    // const data = await getDashboardInsides(ID);
  }

  ratingConvert(str) {
    const MAP = {
      "ONE": 1,
      "TWO": 3,
      "THREE": 3,
      "FOUR": 4,
      "FIVE": 5,
    }
    return MAP[str] || 0;
  }

  async fetchData(page) {
    const { match } = this.props;
    let ID = match.params.id;
    const { apiResponse: data } = await fetchLocationReview(ID, page * this.state.pageSize, this.state.nextPageToken);
    this.setState({
      items: [...this.state.items, ...data.reviews]
    })
    if (data.nextPageToken) {
      this.setState({
        nextPageToken: data.nextPageToken,
        currentPage: this.state.currentPage + 1
      })
    } else {
      this.setState({
        lastPage: true
      })
    }
  }

  componentWillUnmount() {
  }

  render() {
    //meta title
    document.title = "Reviews";

    return (
      <React.Fragment key={this.props?.match?.params?.id}>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs
              title={this.props.t("Dashboards")}
              breadcrumbItem={this.props.t("Reviews")}
            />
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    {console.log(this.state.lastPage, "PAGE LENGTH")}
                    {/*Put the scroll bar always on the bottom*/}
                    <InfiniteScroll
                      pageStart={1}
                      loadMore={this.fetchData.bind(this)}
                      hasMore={!this.state.lastPage}
                      loader={<><div className='d-flex justify-content-center overflow-hidden'>
                        <div
                          className="spinner-border spinner-sm text-dark m-1"
                          role="status"
                        >
                          <span className="sr-only">Loading...</span>
                        </div>
                      </div></>}
                    >
                      {/*  {this.state.items.map((_, index) => (
                          <div key={index}>
                            div - #{index}
                          </div>
                        ))} */}

                      {this.state.items.map((rev, index) => (
                        <div key={index}>
                          <div>
                            <div className="media py-3 border-top">
                              <div className="avatar-xs me-3">
                                <div className="avatar-title rounded-circle bg-light text-primary">
                                  <img src={rev.reviewer?.profilePhotoUrl} alt="" style={{
                                    width: "100%",
                                    height: "100%"
                                  }} />
                                </div>
                              </div>
                              <div className="media-body">
                                <h5 className="font-size-14 mb-1">
                                  {rev?.reviewer?.displayName}{" "}
                                  <small className="text-muted float-end">
                                    {moment(rev.updateTime).fromNow()}
                                  </small>
                                </h5>
                                <div className="rating-component">
                                  <RatingTooltip
                                    max={5}
                                    style={{ justifyContent: 'left' }}
                                    disabled={true}
                                    ActiveComponent={
                                      <i
                                        key={"active_3"}
                                        className="mdi mdi-star text-primary"
                                        style={{ fontSize: "15px" }}
                                      />
                                    }
                                    InActiveComponent={
                                      <i
                                        key={"active_03"}
                                        className="mdi mdi-star-outline text-muted"
                                        style={{ fontSize: "15px" }}
                                      />
                                    }
                                    defaultRating={this.ratingConvert(rev.starRating)}
                                  />
                                </div>
                                {rev.comment && (

                                  <p className="text-muted">
                                    {rev.comment}
                                  </p>
                                )}
                                {!rev.reviewReply && (
                                  <div>
                                    <Link to="#" className="text-success">
                                      <i className="mdi mdi-reply"></i> Reply
                                    </Link>
                                  </div>
                                )}
                                {rev.reviewReply && (
                                  <div className="media pt-3">
                                    <div className="avatar-xs me-3">
                                      <div className="avatar-title rounded-circle bg-light text-primary">
                                        <i className="bx bxs-user"></i>
                                      </div>
                                    </div>
                                    <div className="media-body">
                                      <p className="text-muted">
                                        {rev.reviewReply.comment}
                                      </p>
                                      <div>
                                        <Link to="#" className="text-success">
                                          <i className="mdi mdi-reply"></i>{" "}
                                          Reply
                                        </Link>
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </InfiniteScroll>

                    {/* <div className="">
                      <h5 className="font-size-15">
                        <i className="bx bx-message-dots text-muted align-middle me-1"></i>{" "}
                        Comments :
                      </h5>

                      <div>
                        <div className="media py-3">
                          <div className="avatar-xs me-3">
                            <div className="avatar-title rounded-circle bg-light text-primary">
                              <i className="bx bxs-user"></i>
                            </div>
                          </div>
                          <div className="media-body">
                            <h5 className="font-size-14 mb-1">
                              Delores Williams{" "}
                              <small className="text-muted float-end">
                                1 hr Ago
                              </small>
                            </h5>
                            <p className="text-muted">
                              If several languages coalesce, the grammar
                              of the resulting language is more simple and
                              regular than that of the individual
                            </p>
                            <div>
                              <Link to="#" className="text-success">
                                <i className="mdi mdi-reply"></i> Reply
                              </Link>
                            </div>
                          </div>
                        </div>
                        <div className="media py-3 border-top">
                          <div className="avatar-xs me-3">
                            <img
                              src={avtar1}
                              alt=""
                              className="img-fluid d-block rounded-circle"
                            />
                          </div>
                          <div className="media-body">
                            <h5 className="font-size-14 mb-1">
                              Clarence Smith{" "}
                              <small className="text-muted float-end">
                                2 hrs Ago
                              </small>
                            </h5>
                            <p className="text-muted">
                              Neque porro quisquam est, qui dolorem ipsum
                              quia dolor sit amet
                            </p>
                            <div>
                              <Link to="#" className="text-success">
                                <i className="mdi mdi-reply"></i> Reply
                              </Link>
                            </div>

                            <div className="media pt-3">
                              <div className="avatar-xs me-3">
                                <div className="avatar-title rounded-circle bg-light text-primary">
                                  <i className="bx bxs-user"></i>
                                </div>
                              </div>
                              <div className="media-body">
                                <h5 className="font-size-14 mb-1">
                                  Silvia Martinez{" "}
                                  <small className="text-muted float-end">
                                    2 hrs Ago
                                  </small>
                                </h5>
                                <p className="text-muted">
                                  To take a trivial example, which of us
                                  ever undertakes laborious physical
                                  exercise
                                </p>
                                <div>
                                  <Link to="#" className="text-success">
                                    <i className="mdi mdi-reply"></i>{" "}
                                    Reply
                                  </Link>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="media py-3 border-top">
                          <div className="avatar-xs me-3">
                            <div className="avatar-title rounded-circle bg-light text-primary">
                              <i className="bx bxs-user"></i>
                            </div>
                          </div>
                          <div className="media-body">
                            <h5 className="font-size-14 mb-1">
                              Keith McCoy{" "}
                              <small className="text-muted float-end">
                                12 Aug
                              </small>
                            </h5>
                            <p className="text-muted">
                              Donec posuere vulputate arcu. phasellus
                              accumsan cursus velit
                            </p>
                            <div>
                              <Link to="#" className="text-success">
                                <i className="mdi mdi-reply"></i> Reply
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div> */}
                  </CardBody>
                </Card>
              </Col>
            </Row>
            {/* Render Breadcrumb */}
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

Reviews.propTypes = {
  t: PropTypes.any,
};

const mapStateToProps = ({ Location }) => {
  return ({
    locations: Location.locations,
    isLoading: Location.isLoading,
    selectedLocation: Location.selectedLocation,
    isLocationSelected: Location.isSelectedLocation
  })
};

const mapDispatchToProps = dispatch => ({
  selectLocation: (id, history) => dispatch(selectLocation(id, history)),
});

export default (withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(Reviews))));
