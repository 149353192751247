import React, { Component, useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Container,
  Card,
  CardBody,
  Col,
  Row,
  Table,
} from "reactstrap";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { withRouter } from "react-router";

//i18n
import { withTranslation } from "react-i18next";

import classNames from "classnames";

// import images
import img1 from "../../assets/images/small/img-2.jpg"
import avtar1 from "../../assets/images/users/avatar-2.jpg"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

// Import FecthAPI
import { selectLocation } from "../../store/actions";
import moment from "moment";
import RatingTooltip from "react-rating-tooltip"
import { deleteLocalPost, deleteSchedulePost, fetchLocationLocalPost, updateLocalPost } from "../../helpers/backend_helper";
import PaginationComponet from "./PaginationComponet";
import AddPost from "./Component/AddPost";
import { reject } from "lodash";
import { Empty, Input, Menu, message, Modal, Popconfirm, Tooltip } from "antd";
import { MailOutlined, SettingOutlined, AppstoreOutlined, SendOutlined, FieldTimeOutlined } from '@ant-design/icons';

function getItem(label, key, icon, children, type) {
  return {
    key,
    icon,
    children,
    label,
    type,
  };
}

const items = [
  getItem('Posts', 'sub1', <AppstoreOutlined />),
  // getItem('Questions & Reviews', 'sub2', <AppstoreOutlined />),
  // getItem('Reports', 'sub3', <AppstoreOutlined />),
  /*  getItem('Questions & Answer', 'sub2', <AppstoreOutlined />, [
     getItem('Option 5', '5'),
     getItem('Option 6', '6'),
     getItem('Submenu', 'sub3', null, [getItem('Option 7', '7'), getItem('Option 8', '8')]),
   ]), */
];

// submenu keys of first level
const rootSubmenuKeys = ['sub1', 'sub2', 'sub3'];
const MenuComponentXX = () => {
  const [openKeys, setOpenKeys] = useState(['sub1']);
  const onOpenChange = (keys) => {
    const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
    if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      setOpenKeys(keys);
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    }
  };
  return (
    <Menu
      mode="inline"
      openKeys={openKeys}
      defaultSelectedKeys={'sub1'}
      onOpenChange={onOpenChange}
      items={items}
    />
  );
};

const MenuComponent = (props) => {
  return (
    <>
      <div className="cst-menu-body">
        <div className={`cst-menu-body-item ${props.openPage == PAGE_1 ? 'active' : ''}`} onClick={function () {
          props.setOpenPage(PAGE_1)
        }}>
          <SendOutlined /> Posts
        </div>
        <div className={`cst-menu-body-item ${props.openPage == PAGE_2 ? 'active' : ''}`} onClick={function () {
          props.setOpenPage(PAGE_2)
        }}>
          <FieldTimeOutlined /> Schedule Posts
        </div>
      </div>

    </>
  );
};

const PAGE_1 = "Post";
const PAGE_2 = "Schedule Post";


class Page1 extends PaginationComponet {
  constructor(props) {
    super(props);
    this.state = {
      ...this.state,
      pageSize: 10,
      ID: props.ID,

      openPost: false,
      summaryText: "",
      postName: "",
      isLoadingPostUpdate: false,
    };

    this.dataKey = "localPosts";
    this.fetchAPI = fetchLocationLocalPost;

    this.handleCancel = this.handleCancel.bind(this);
    this.handleOkUpdatePost = this.handleOkUpdatePost.bind(this);

  }

  handleOkUpdatePost() {
    console.log("XALLED")
    this.setState({
      isLoadingPostUpdate: true,
    })

    const that = this;
    updateLocalPost({
      name: this.state.postName,
      summary: this.state.summaryText,
    }).then(res => {
      this.setState({
        items: this.state.items.map(i => {
          if (i.name == that.state.postName) {
            return {
              ...i,
              summary: that.state.summaryText,
            }
          } else {
            return i
          }
        })
      })
      message.success("Post updated");
    }).finally(() => {
      that.handleCancel();
    })
  }

  handleCancel() {
    this.setState({
      openPost: false,
      summaryText: "",
      postName: "",
      isLoadingPostUpdate: false,
    })
  }

  deletePost(name) {
    return new Promise(async (resolve, reject) => {
      try {
        await deleteLocalPost(name);
        message.success("Post deleted")
        this.setState({
          items: this.state.items.filter(i => i.name != name)
        })
        resolve(true)
      } catch (err) {
        reject(false)
      }
    })
  }

  render() {
    return (
      <>
        <Modal
          title="Update Post"
          open={this.state.openPost}
          onOk={this.handleOkUpdatePost}
          okText="Update"
          confirmLoading={this.state.isLoadingPostUpdate}
          onCancel={this.handleCancel}
        >
          <Input.TextArea rows={4} value={this.state.summaryText} onChange={(e => {
            this.setState({
              summaryText: e.target.value
            })
          }).bind(this)}></Input.TextArea>
        </Modal>
        <Table className="table mb-0">
          <thead className="table-light">
            <tr>
              <th style={{ width: "120px" }}>PUBLISHED ON</th>
              <th>POST CONTENT</th>
              <th style={{ width: "50px" }}>Action</th>
            </tr>
          </thead>

          <this.InfiniteScroll element="tbody">
            {!this.state.items.length && this.state.lastPage && (
              <tr>
                <div className="position-absolute d-flex justify-content-center w-100">
                  <Empty />
                </div>
              </tr>
            )}
            {this.state.items.map((itm, index) => (
              <tr key={itm.name}>
                <td>
                  <h6 className="no-wordwrap">
                    {moment(itm.createTime).format("D MMM YYYY")}
                  </h6>
                  {moment(itm.createTime).format("HH:mm A")}
                </td>
                <td>
                  <div className="post-container">
                    <div className="post-image">
                      <img src={itm?.media?.[0]?.googleUrl} alt="" />
                    </div>
                    <div className="post-content">
                      {itm.summary}
                    </div>
                  </div>
                </td>
                <td>
                  <Tooltip title={'Edit'}>

                    <i
                      style={{
                        marginRight: '5px'
                      }}
                      className="zso-edit-line cursor-pointer font-size-18 mr-2"
                      onClick={(() => this.setState({
                        summaryText: itm.summary,
                        postName: itm.name,
                        openPost: true
                      })).bind(this)}
                    ></i>
                  </Tooltip>
                  <Popconfirm
                    title="Are you sure?"
                    className="text-danger cursor-pointer"
                    description="You want to Delete"
                    onConfirm={() => this.deletePost.bind(this)(itm.name)}
                    onOpenChange={() => console.log('open change')}
                  >
                    <Tooltip title={'Delete'}>
                      <i
                        className="zso-delete font-size-22"
                        id="deletetooltip"
                      ></i>
                    </Tooltip>
                  </Popconfirm>
                </td>
              </tr>
            ))}
          </this.InfiniteScroll>
        </Table>
      </>
    )
  }
}
class Page2 extends PaginationComponet {
  constructor(props) {
    super(props);
    this.state = {
      ...this.state,
      pageSize: 10,
      ID: props.ID,
    };

    this.dataKey = "schedulePosts";
    this.fetchAPI = fetchLocationLocalPost;

  }

  deletePost(id) {
    return new Promise(async (resolve, reject) => {
      try {
        await deleteSchedulePost(id);
        message.success("Post deleted")
        this.setState({
          items: this.state.items.filter(i => i.id != id)
        })
        resolve(true)
      } catch (err) {
        reject(false)
      }
    })
  }

  render() {
    return (
      <>
        <Table className="table mb-0">
          <thead className="table-light">
            <tr>
              <th style={{ width: "120px" }}>PUBLISHED ON</th>
              <th>POST CONTENT</th>
              <th>CREATE TIME</th>
              <th style={{ width: "50px" }}>Action</th>
            </tr>
          </thead>

          <this.InfiniteScroll element="tbody">
            {!this.state.items.length && this.state.lastPage && (
              <tr>
                <div className="position-absolute d-flex justify-content-center w-100">
                  <Empty />
                </div>
              </tr>
            )}
            {this.state.items.map((itm, index) => (
              <tr key={itm.name}>
                <td>
                  <h6 className="no-wordwrap">
                    {moment(itm.release_date, "YYYY-MM-DD HH:mm:ss").format("D MMM YYYY")}
                  </h6>
                  {moment(itm.release_date, "YYYY-MM-DD HH:mm:ss").format("HH:mm A")}
                </td>
                <td>
                  <div className="post-container">
                    <div className="post-image">
                      <img src={itm?.media_url} alt="" />
                    </div>
                    <div className="post-content">
                      {itm.summary}
                    </div>
                  </div>
                </td>

                <td>
                  <h6 className="no-wordwrap">
                    {moment(itm.created_at).format("D MMM YYYY")}
                  </h6>
                  {moment(itm.created_at).format("HH:mm A")}
                </td>
                <td>
                  <Popconfirm
                    title="Are you sure?"
                    className="text-danger cursor-pointer"
                    description="You want to Delete"
                    onConfirm={() => this.deletePost.bind(this)(itm.id)}
                    onOpenChange={() => console.log('open change')}
                  >
                    <Tooltip title={'Delete'}>

                      <i
                        className="zso-delete font-size-22"
                        id="deletetooltip"
                      ></i>
                    </Tooltip>
                  </Popconfirm>
                </td>
              </tr>
            ))}
          </this.InfiniteScroll>
        </Table>
      </>
    )
  }
}

Page1 = withRouter(Page1);
Page2 = withRouter(Page2);

class Posts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...this.state,
      pageSize: 10,
      openPage: PAGE_1,
      ID: this.props.match.params.id || 0
    };

    this.dataKey = "localPosts";
    this.fetchAPI = fetchLocationLocalPost;
    this.setOpenPage = this.setOpenPage.bind(this);

  }


  async componentDidMount() {
    console.log("===============MOUNT===============")
  }

  componentWillUnmount() {
  }



  setOpenPage(openPage) {
    if (openPage == PAGE_1) {
      this.dataKey = "localPosts"
    } else if (openPage == PAGE_2) {
      this.dataKey = "schedulePosts"
    }
    this.setState({ openPage })
  }

  render() {
    //meta title
    document.title = "Posts";

    return (
      <React.Fragment key={this.props?.match?.params?.id}>
        <div className="page-content">
          <Container fluid>
            <div>
              <div className="row">
                <div className="col-2 sub-navigation post-page">
                  <div className="sub-navigation-container">
                    <div className="cst-menu">
                      <div className="cst-menu-header">
                        <div className="cst-menu-header-text">GMB Posts</div>
                      </div>
                    </div>
                    <MenuComponent openPage={this.state.openPage} setOpenPage={this.setOpenPage} />
                  </div>
                </div>
                <div className="col-2"></div>
                <div className="col-10">
                  {/* <Breadcrumbs
                  title={this.props.t("Dashboards")}
                  breadcrumbItem={this.props.t("Posts")}
                /> */}

                  <Row>
                    <Col lg="12">
                      <Card>
                        <CardBody className="p-0">
                          {/*Put the scroll bar always on the bottom*/}
                          <div className="table-responsive">
                            {this.state.openPage == PAGE_1 && (
                              <Page1 ID={this.state.ID} />
                            )}
                            {this.state.openPage == PAGE_2 && (
                              <Page2 ID={this.state.ID} />
                            )}
                          </div>

                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </div>
              </div>
              {/* Render Breadcrumb */}
            </div>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

Posts.propTypes = {
  t: PropTypes.any,
};

const mapStateToProps = ({ Location }) => {
  return ({
    locations: Location.locations,
    isLoading: Location.isLoading,
    selectedLocation: Location.selectedLocation,
    isLocationSelected: Location.isSelectedLocation
  })
};

const mapDispatchToProps = dispatch => ({
  selectLocation: (id, history) => dispatch(selectLocation(id, history)),
});

export default (withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(Posts))));
