import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import {  } from "./actionTypes"
import { setLoading2 } from "./actions"

//Include Both Helper File with needed methods
import service from "../../helpers/Api/FetchInterceptor";

// function* meCall(action) {
//   yield put(setLoading(true));
//   try {
//     const response = yield call(service, action.payload);
//     yield put(success(response));
//   } catch (error) {
//     yield put(failure(error));
//   } finally {
//     yield put(setLoading(false));
//   }

// }

function* meSaga() {
  // yield takeEvery(API_CALL, meCall)
}


export default meSaga
