import React, { Component, useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Container,
  Card,
  CardBody,
  Col,
  Row,
  Modal,
  Button,
  ModalHeader,
  ModalBody,
  Label,
} from "reactstrap";
import { Link } from "react-router-dom";
import { connect } from "react-redux";


//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

//i18n
import { withTranslation } from "react-i18next";
import classNames from "classnames";

// Import FecthAPI
import PaginateTable from "../../components/UI/Table";
import service from "../../helpers/Api/FetchInterceptor";
import Lightbox from "react-image-lightbox";
import { deleteNotification, getNotifications } from "../../store/actions";
import PaginateTableSaga from "../../components/UI/Table/TableSaga";
import { Popconfirm, Tag } from "antd";
import moment from "moment";

function Test(props) {
  const [visible, setVisible] = useState(false)
  return (
    <React.Fragment>
      <img src={props.row.image} style={{ width: '200px' }} onClick={() => setVisible(true)} />
      {visible && (
        <Lightbox
          mainSrc={props.row.image}
          enableZoom={false}
          imageCaption={props.row.name}
          onCloseRequest={() => { setVisible(false) }}
        />
      )}
    </React.Fragment>
  )
}

class Notifications extends Component {
  constructor(props) {
    super(props);
    this.state = {
      customQuery: {}
    };

    this.tableColumns = [
      { 
        title: "ID",
        dataIndex: "id",
        sorter: true,
      },
      {
        title: "Users",
        dataIndex: "user_type",
        sorter: true,
      },
      {
        title: "Title",
        dataIndex: "title",
        sorter: true,
      },
      {
        title: "Description",
        dataIndex: "description",
        sorter: true,
      },
      {
        title: "Status",
        dataIndex: "delivered",
        render: (text, row) => {
          return (
            <>
              {row.delivered == 1 ? (
                <Tag color={"green"}>Yes</Tag>
              ) : (
                <Tag color={"red"}>No</Tag>
              )}
            </>
          );
        },
        sorter: true,
      },
      {
        title: "Sent Date",
        dataIndex: "created_at",
        render: (text, row) => {
          return (
            <>
              <Tag>
                {moment(row.created_at).format('YYYY-MM-DD')}
              </Tag>
            </>
          );
        },
        sorter: true,
      },
    ];
  }

  async fetchApi(params) {
    let result = await service.get('/notifications' + "?" + params);
    return result.data;
  }

  componentDidMount() {
    // this.props.fetchNotification("");
  }

  componentDidUpdate(prevProps) {
  }

  render() {
    //meta title
    document.title = "Notifications";

    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs
              title={this.props.t("Dashboards")}
              breadcrumbItem={this.props.t("Notifications")}
            />
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <Row className="mb-4">
                      <Col sm="4">
                      </Col>
                      <Col sm="8">
                        <div className="text-sm-end">
                          <Link to={'/notifications/add'}>
                            <Button
                              color="primary"
                              className="font-16 btn-block btn btn-primary"
                            // onClick={this.handleUserClicks}
                            >
                              <i className="mdi mdi-plus-circle-outline me-1" />
                              Create New Notification
                            </Button>
                          </Link>
                        </div>
                      </Col>
                    </Row>
                    <PaginateTableSaga fetch={this.props.fetchNotification} isLoading={this.props.notificationState.isLoading} columns={this.tableColumns} list={this.props.notifications} pagination={this.props.notificationState.pagination} customeQuery={this.customQuery} />
                  </CardBody>
                </Card>
              </Col>
            </Row>
            {/* Render Breadcrumb */}
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

Notifications.propTypes = {
  t: PropTypes.any,
};

const mapStateToProps = ({ Notification }) => {
  return ({
    notificationState: Notification,
    notifications: Notification.list,

  })
};

const mapDispatchToProps = dispatch => ({
  fetchNotification: (params) => dispatch(getNotifications(params)),
  deleteNotification: (prams) => dispatch(deleteNotification(prams))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(Notifications));
