/* TASKS */
export const NOTIFICATION_LOADING_1 = 'NOTIFICATION_LOADING_1'
export const NOTIFICATION_LOADING_2 = 'NOTIFICATION_LOADING_2'
export const GET_NOTIFICATIONS = 'GET_NOTIFICATIONS'
export const GET_NOTIFICATIONS_SUCCESS = 'GET_NOTIFICATIONS_SUCCESS'
export const GET_NOTIFICATIONS_FAIL = 'GET_NOTIFICATIONS_FAIL'
export const ADD_NOTIFICATION = 'ADD_NOTIFICATION'
export const GET_SPECIFIC_NOTIFICATION = 'GET_SPECIFIC_NOTIFICATION'
export const UPDATE_NOTIFICATION = 'UPDATE_NOTIFICATION'
export const UPDATE_NOTIFICATION_SUCCESS = 'UPDATE_NOTIFICATION_SUCCESS'
export const DELETE_NOTIFICATION = 'DELETE_NOTIFICATION'
export const DELETE_NOTIFICATION_SUCCESS = 'DELETE_NOTIFICATION_SUCCESS'
export const NOTIFICATION_TEST = 'NOTIFICATION_TEST'
