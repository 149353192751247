import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import { ADD_NOTIFICATION, NOTIFICATION_TEST, DELETE_NOTIFICATION, GET_NOTIFICATIONS, GET_SPECIFIC_NOTIFICATION, UPDATE_NOTIFICATION } from "./actionTypes"
import { getNotificationSuccess, getNotificationFail, updateNotificationLoading1, updateNotificationSuccess, deleteNotificationSuccess } from "./actions"
import service from "../../helpers/Api/FetchInterceptor"

//Include Both Helper File with needed methods
function getNotifications(params) {
  return async function () {
    let result = await service.get('/notifications' + "?" + params);
    return result.data;
  }
}
function getNotification(id) {
  return async function () {
    let result = await service.get('/notifications/' + id);
    return result.data;
  }
}

function putNotification(notification) {
  return async function () {
    const formData = new FormData();
    Object.keys(notification).forEach(key => formData.append(key, object[key]));
    let result = await service.put('/notifications', {
      data: formData
    });
    return result.data;
  }
}

function* fetchNotification({
  payload: params
}) {
  try {
    const response = yield call(getNotifications(params))
    yield put(getNotificationSuccess(response))
  } catch (error) {
    yield put(getNotificationFail(error))
  }
}

function* addNotification({
  payload
}) {
  try {
    yield put(updateNotificationLoading1(true))
    const response = yield call(() => payload)
    yield put(updateNotificationSuccess(response.data.notification))
  } catch (error) {
    yield put(getNotificationFail(error))
  } finally {
    yield put(updateNotificationLoading1(false))
  }
}

function* updateNotification({
  payload
}) {
  try {
    yield put(updateNotificationLoading1(true))
    const response = yield call(() => payload)
    yield put(updateNotificationSuccess(response.data.notification))
  } catch (error) {
    yield put(getNotificationFail(error))
  } finally {
    yield put(updateNotificationLoading1(false))
  }
}

function* fetchNotificationWithId({
  payload
}) {
  try {
    yield put(updateNotificationLoading1(true))
    const response = yield call(() => payload)
    yield put(updateNotificationSuccess(response.data.notification))
  } catch (error) {
    yield put(getNotificationFail(error))
  } finally {
    yield put(updateNotificationLoading1(false))
  }
}

function* deleteNotification({
  payload
}) {
  try {
    yield put(updateNotificationLoading1(true))
    const response = yield call(() => payload)
    console.log('called', response)
    yield put(deleteNotificationSuccess(response.data.notification))
  } catch (error) {
    yield put(getNotificationFail(error))
  } finally {
    yield put(updateNotificationLoading1(false))
  }
}

function* fetchTest({
  payload
}) {
  try {
    yield put(updateNotificationLoading1(true))
    const response = yield call(() => payload)
  } catch (error) {
    yield put(getNotificationFail(error))
  } finally {
    yield put(updateNotificationLoading1(false))
  }
}

function* notificationsSaga() {
  yield takeEvery(GET_NOTIFICATIONS, fetchNotification)
  yield takeEvery(ADD_NOTIFICATION, addNotification)
  yield takeEvery(UPDATE_NOTIFICATION, updateNotification)
  yield takeEvery(GET_SPECIFIC_NOTIFICATION, fetchNotificationWithId)
  yield takeEvery(DELETE_NOTIFICATION, deleteNotification)
  yield takeEvery(NOTIFICATION_TEST, fetchTest)
}

export default notificationsSaga
