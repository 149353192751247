import service from "../../helpers/Api/FetchInterceptor"
import { ADD_USER, USER_LOADING_1, USER_LOADING_2, USER_TEST, DELETE_USER, DELETE_USER_SUCCESS, GET_USERS, GET_USERS_FAIL, GET_USERS_SUCCESS, GET_SPECIFIC_USER, UPDATE_USER, UPDATE_USER_SUCCESS } from "./actionTypes"

export const getUsers = (payload) => ({
  type: GET_USERS,
  payload: payload
})

export const getUserSuccess = users => ({
  type: GET_USERS_SUCCESS,
  payload: users,
})

export const getUserFail = error => ({
  type: GET_USERS_FAIL,
  payload: error,
})

export const addUser = (payload) => {
  const formData = new FormData();
  Object.keys(payload).forEach(key => formData.append(key, payload[key]));
  return {
    type: ADD_USER,
    payload: service({
      method: "POST",
      url: "/user",
      data: formData
    })
  }
}
export const updateUser = (id, payload) => {
  const formData = new FormData();
  Object.keys(payload).forEach(key => formData.append(key, payload[key]));
  return {
    type: UPDATE_USER,
    payload: service({
      method: "POST",
      url: "/user/" + id,
      data: formData
    })
  }
}
export const updateUserSuccess = (payload) => ({
  type: UPDATE_USER_SUCCESS,
  payload
})

export const deleteUser = (id) => ({
  type: DELETE_USER,
  payload: service({
    method: "POST",
    url: "/user/" + id,
    data: {
      '_method': 'delete'
    }
  })
})

export const deleteUserSuccess = (payload) => ({
  type: DELETE_USER_SUCCESS,
  payload
})

export const getSpecificUser = (payload) => ({
  type: GET_SPECIFIC_USER,
  payload: service.get("/user/" + payload)
})


export const updateUserLoading1 = (payload) => ({
  type: USER_LOADING_1,
  payload
})
export const updateUserLoading2 = (payload) => ({
  type: USER_LOADING_2,
  payload
})