import React, { Component, useState, useEffect } from "react";
import PropTypes from "prop-types";
import InfiniteScroll from "react-infinite-scroller";

function Infinite(props) {
  return (
    <>
      <InfiniteScroll
        element={props?.element || 'div'}
        pageStart={1}
        loadMore={this.fetchData.bind(this)}
        hasMore={!this.state.lastPage}
        loader={<><div className='d-flex justify-content-center overflow-hidden'>
          <div
            className="spinner-border spinner-sm text-dark m-1"
            role="status"
          >
            <span className="sr-only">Loading...</span>
          </div>
        </div></>}
      >
        {props.children}
      </InfiniteScroll>
    </>
  )
}

class PaginationComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPage: 1,
      apiResponse: {},
      pageSize: 20,
      nextPageToken: "",
      lastPage: false,
      items: []
    };
    this.InfiniteScroll = Infinite.bind(this)
  }
  dataKey = "reviews"
  fetchAPI() { }

  async fetchData(page) {
    const { match } = this.props;
    let ID = match.params.id;
    const { apiResponse: data } = await this.fetchAPI(ID, this.state.pageSize, this.state.nextPageToken);
    this.setState({
      apiResponse: data,
      items: [...this.state.items, ...data?.[this.dataKey] || []]
    })
    if (data.nextPageToken) {
      this.setState({
        nextPageToken: data.nextPageToken,
        currentPage: this.state.page
      })
    } else {
      this.setState({
        lastPage: true
      })
    }
  }


}

PaginationComponent.propTypes = {

};

const mapStateToProps = ({ Location }) => {
  return ({
  })
};

const mapDispatchToProps = dispatch => ({
});

export default PaginationComponent;
