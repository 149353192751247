export const cardEnum = function (type) {
  switch (type) {
    case "SEARCH":
      return 1;
    case "MAP":
      return 2;
    case "VISITOR":
      return 3;
    case "CONVERSATION":
      return 4;
    case "R_Q_SUMMARY":
      return 5;
    case "PERFORMANCE_SUMMARY":
      return 6;
    case "REVIEW_GROWTH":
      return 7;
    case "QUESTION_GROWTH":
      return 8;
    case "ALL_SUMARY":
      return 9;
  }
}