import { combineReducers } from "redux";

// Front
import Layout from "./layout/reducer";

// Authentication
import Login from "./auth/login/reducer";
import Account from "./auth/register/reducer";
import ForgetPassword from "./auth/forgetpwd/reducer";
import Profile from "./auth/profile/reducer";

//E-commerce
import ecommerce from "./e-commerce/reducer";

//Calendar
import calendar from "./calendar/reducer";

//chat
import chat from "./chat/reducer";

//crypto
import crypto from "./crypto/reducer";

//invoices
import invoices from "./invoices/reducer";

//projects
import projects from "./projects/reducer";

//tasks
import tasks from "./tasks/reducer";


//mails
import mails from "./mails/reducer";

//Dashboard
import Dashboard from "./dashboard/reducer";

//Dasboard saas
import DashboardSaas from "./dashboard-saas/reducer";

import Banner from "./banner/reducer";
import Sponser from "./sponser/reducer";
import Webinar from "./webinar/reducer";
import Notification from "./notification/reducer";
import Me from "./me/reducer";
import Location from "./locations/reducer";
import User from "./users/reducer"
import CustomReport from "./customCard"
import BasicReport from "./basicCard"

const rootReducer = combineReducers({
  // public
  Layout,
  Login,
  Account,
  ForgetPassword,
  Profile,
  ecommerce,
  calendar,
  chat,
  mails,
  crypto,
  invoices,
  projects,
  tasks,
  Dashboard,
  DashboardSaas,
  Banner,
  Sponser,
  Webinar,
  Notification,
  Me,
  Location,
  User,
  CustomReport,
  BasicReport
});

export default rootReducer;
