import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { withRouter } from "react-router";
import { connect } from 'react-redux'
import { Link } from "react-router-dom"
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap"
import Select from "react-select"
import Dropzone from "react-dropzone"
import Breadcrumbs from '../../components/Common/Breadcrumb'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import * as Yup from "yup";
import Switch from "react-switch"
import { addNotification, getNotifications, getSpecificNotification, getTest, updateNotification } from '../../store/actions'
import { message, Spin } from 'antd'

const Offsymbol = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        fontSize: 12,
        color: "#fff",
        paddingRight: 2
      }}
    >
      {" "}
      No
    </div>
  )
}

const OnSymbol = props => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        fontSize: 12,
        color: "#fff",
        paddingRight: 2
      }}
    >
      {" "}
      Yes
    </div>
  )
}

export class NotificationOperations extends Component {
  static propTypes = {
    type: PropTypes.any
  }


  constructor(props) {
    super(props)
    this.state = {
      selectedFiles: [],
      isSubmiting: false,
      // userTypes: ['Delegate', 'Farmer', 'Student', 'Sr. Citizen', 'Foreign delegate', 'Representative of Indian exhibitor', 'Representative of Foreign Exhibitor']
      userTypes: [
        { label: "Delegate", value: "Delegate" },
        { label: "Farmer", value: "Farmer" },
        { label: "Student", value: "Student" },
        { label: "Sr. Citizen", value: "Sr. Citizen" },
        { label: "Foreign delegate", value: "Foreign delegate" },
        { label: "Representative of Indian exhibitor", value: "Representative of Indian exhibitor" },
        { label: "Representative of Foreign Exhibitor", value: "Representative of Foreign Exhibitor" },
      ]
    }
  }


  handleAcceptedFiles = files => {
    files.map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: this.formatBytes(file.size),
      })
    )

    this.setState({ selectedFiles: files })
  }

  formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return "0 Bytes"
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
  }

  componentDidMount() {
    const { match } = this.props;
    // this.props.getTest().payload.then(res => message.success("API CALLED"))
    if (this.props.type == "EDIT") {
      console.log('Getting ID', match.params.id)
      this.props.getSpecificNotification(match.params.id)
    }
  }

  render() {
    return (
      <React.Fragment>
        {console.log(this.props.notificationLoading1, 'notificationLoading1', this.props.notificationState)}
        <div className="page-content">
          <Container fluid>
            {/* Render Breadcrumb */}
            <Breadcrumbs title="Notifications" breadcrumbItem={`${this.props.type == "ADD" ? 'Add' : 'Update'} Notification`} />

            <Row>
              <Col xs="12">
                <Spin spinning={!!this.props.notificationLoading1}>
                  <Card>
                    <CardBody>
                      <CardTitle className="h4">Basic Information</CardTitle>
                      <Formik
                        enableReinitialize={true}
                        initialValues={{
                          ...(this.props.type == "EDIT" ? this.props.notification : {}),
                        }}
                        validationSchema={
                          Yup.object().shape({
                            title: Yup.string().required(
                              "Please Enter Title"
                            ),
                            description: Yup.string().required(
                              "Please Enter Description"
                            ),
                            user_type: Yup.mixed().required('File is required')
                          })}
                        onSubmit={values => {
                          const that = this;
                          this.setState({ isSubmiting: true })
                          values.is_active = values.is_active ? 1 : 0;
                          let apiSubmit = null;
                          if (this.props.type == "EDIT") {
                            if (!(values.image instanceof File)) {
                              delete values['image']
                            }
                            values._method = 'patch'
                            apiSubmit = this.props.updateNotification(this.props.match.params.id, values)
                          } else {
                            apiSubmit = this.props.addNotification(values)
                          }
                          console.log(apiSubmit)
                          apiSubmit
                            .payload
                            .then(res => {
                              message.success(res.message);
                              that.props.history.push("/notifications");
                            })
                            .catch(err => message.error('Something wrong'))
                            .finally(() => {
                              console.log(that.state)
                              that.setState({ 'isSubmiting': false });
                            })
                        }}
                      >
                        {({ errors, status, touched, setFieldValue, validateForm, handleBlur, handleChange, values, }) => (
                          <Form className="form-horizontal">
                            <Row>
                              <Col sm="6">
                                <div className="mb-3">
                                  <Label for="title" className="form-label">
                                    Notification Title *
                                  </Label>
                                  <Field
                                    name="title"
                                    type="text"
                                    className={
                                      "form-control" +
                                      (errors.title
                                        ? " is-invalid"
                                        : "")
                                    }
                                  />
                                  <ErrorMessage
                                    name="title"
                                    component="div"
                                    className="invalid-feedback"
                                  />
                                </div>
                              </Col>
                              <Col sm="6">
                                <div className="mb-3">
                                  <Label htmlFor="productname">Notification Description *</Label>
                                  <Field
                                    name="description"
                                    type="textarea"
                                    className={
                                      "form-control" +
                                      (errors.description
                                        ? " is-invalid"
                                        : "")
                                    }
                                  />
                                  <ErrorMessage
                                    name="description"
                                    component="div"
                                    className="invalid-feedback"
                                  />
                                </div>
                              </Col>
                              <Col sm="6">
                                <div className="mb-3">
                                  <Label htmlFor="productname">Notification User Type *</Label>
                                  <select
                                    name="user_type"
                                    className={
                                      "form-control" +
                                      (errors.title
                                        ? " is-invalid"
                                        : "")
                                    }
                                    value={values.user_type}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    style={{ display: "block" }}
                                  >
                                    <option value={''} label={''}>
                                      Select a User type
                                    </option>
                                    {this.state.userTypes.map(val => {
                                      return (
                                        <option key={val.value} value={val.value} label={val.label}>
                                          Select a color{" "}
                                        </option>
                                      )
                                    })}

                                  </select>
                                  <ErrorMessage
                                    name="user_type"
                                    component="div"
                                    className="invalid-feedback"
                                  />
                                </div>
                              </Col>

                            </Row>
                            <div className="d-flex flex-wrap gap-2 mt-4">
                              <Button
                                type="submit"
                                color="primary"
                                disabled={this.state.isSubmiting}
                              >

                                {this.state.isSubmiting ? ' Saving....' : 'Save Changes'}
                              </Button>
                            </div>
                          </Form>
                        )}
                      </Formik>

                    </CardBody>
                  </Card>
                </Spin>

              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state) => ({
  notificationState: state.Notification,
  notification: state.Notification.single,
  notificationLoading1: state.Notification.loading1
})

const mapDispatchToProps = dispatch => ({
  getTest: (d) => dispatch(getTest(d)),
  addNotification: payload => dispatch(addNotification(payload)),
  updateNotification: function () { return dispatch(updateNotification(...arguments)) },
  getSpecificNotification: payload => dispatch(getSpecificNotification(payload))
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(NotificationOperations))

