import React, { Component, useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Container,
  Card,
  CardBody,
  Col,
  Row,
  Modal,
  Button,
  ModalHeader,
  ModalBody,
  Label,
} from "reactstrap";
import { Link } from "react-router-dom";
import { connect } from "react-redux";


//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

//i18n
import { withTranslation } from "react-i18next";
import classNames from "classnames";

// Import FecthAPI
import PaginateTable from "../../components/UI/Table";
import service from "../../helpers/Api/FetchInterceptor";
import Lightbox from "react-image-lightbox";
import { deleteSponser, getSponsers } from "../../store/actions";
import PaginateTableSaga from "../../components/UI/Table/TableSaga";
import { Popconfirm, Tag } from "antd";

function Test(props) {
  const [visible, setVisible] = useState(false)
  return (
    <React.Fragment>
      <img src={props.row.image} style={{ width: '200px' }} onClick={() => setVisible(true)} />
      {visible && (
        <Lightbox
          mainSrc={props.row.image}
          enableZoom={false}
          imageCaption={props.row.name}
          onCloseRequest={() => { setVisible(false) }}
        />
      )}
    </React.Fragment>
  )
}

class Sponsers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      customQuery: {}
    };

    this.tableColumns = [
      { 
        title: "ID",
        dataIndex: "id",
        sorter: true,
      },
      {
        title: "Image",
        dataIndex: "",
        render: function (_, row) {
          return (
            <Test row={row} />
          )
        }
      },
      {
        title: "Name",
        dataIndex: "name",
        sorter: true,
      },
      {
        title: "Title",
        dataIndex: "title",
        sorter: true,
      },
      {
        title: "Description",
        dataIndex: "description",
        sorter: true,
      },
      {
        title: "Status",
        dataIndex: "is_active",
        render: (text, row) => {
          return (
            <>
              {row.is_active == 1 ? (
                <Tag color={"green"}>Active</Tag>
              ) : (
                <Tag color={"red"}>In Active</Tag>
              )}
            </>
          );
        },
        sorter: true,
      },
      {
        title: "Action",
        dataIndex: "",
        sorter: false,
        render: (_, row) => (
          <>
            <div className="d-flex gap-3">
              <Link className="text-success" to={`/sponsers/${row.id}/edit`}>
                <i
                  className="mdi mdi-pencil font-size-18"
                  id="edittooltip"
                // onClick={() => this.handleUserClick(user)}
                ></i>
              </Link>
              <Popconfirm
                title="Are you sure?"
                className="text-danger cursor-pointer"
                description="You want to Delete"
                onConfirm={() => this.props.deleteSponser(row.id).payload}
                onOpenChange={() => console.log('open change')}
              >
                <i
                  className="mdi mdi-delete font-size-18"
                  id="deletetooltip"
                // onClick={() => this.onClickDelete(user)}
                ></i>
              </Popconfirm>
              <Link className="text-danger" to="#">
              </Link>
            </div>
          </>
        )
      },
    ];
  }

  async fetchApi(params) {
    let result = await service.get('/sponsers' + "?" + params);
    return result.data;
  }

  componentDidMount() {
    // this.props.fetchSponser("");
  }

  componentDidUpdate(prevProps) {
  }

  render() {
    //meta title
    document.title = "Sponsers";

    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs
              title={this.props.t("Dashboards")}
              breadcrumbItem={this.props.t("Sponsers")}
            />
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <Row className="mb-4">
                      <Col sm="4">
                      </Col>
                      <Col sm="8">
                        <div className="text-sm-end">
                          <Link to={'/sponsers/add'}>
                            <Button
                              color="primary"
                              className="font-16 btn-block btn btn-primary"
                            // onClick={this.handleUserClicks}
                            >
                              <i className="mdi mdi-plus-circle-outline me-1" />
                              Create New Sponser
                            </Button>
                          </Link>
                        </div>
                      </Col>
                    </Row>
                    <PaginateTableSaga fetch={this.props.fetchSponser} isLoading={this.props.sponserState.isLoading} columns={this.tableColumns} list={this.props.sponsers} pagination={this.props.sponserState.pagination} customeQuery={this.customQuery} />
                  </CardBody>
                </Card>
              </Col>
            </Row>
            {/* Render Breadcrumb */}
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

Sponsers.propTypes = {
  t: PropTypes.any,
};

const mapStateToProps = ({ Sponser }) => {
  return ({
    sponserState: Sponser,
    sponsers: Sponser.list,

  })
};

const mapDispatchToProps = dispatch => ({
  fetchSponser: (params) => dispatch(getSponsers(params)),
  deleteSponser: (prams) => dispatch(deleteSponser(prams))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(Sponsers));
