import service from "../../helpers/Api/FetchInterceptor"
import { ADD_LOCATION, LOCATION_LOADING_1, LOCATION_LOADING_2, LOCATION_TEST, DELETE_LOCATION, DELETE_LOCATION_SUCCESS, GET_LOCATIONS, GET_LOCATIONS_FAIL, GET_LOCATIONS_SUCCESS, GET_SPECIFIC_LOCATION, UPDATE_LOCATION, UPDATE_LOCATION_SUCCESS, SELECT_LOCATION } from "./actionTypes"

export const getLocations = (payload, history) => ({
  type: GET_LOCATIONS,
  payload: {payload, history}
})

export const getLocationSuccess = locations => ({
  type: GET_LOCATIONS_SUCCESS,
  payload: locations,
})

export const getLocationFail = error => ({
  type: GET_LOCATIONS_FAIL,
  payload: error,
})

export const addLocation = (payload) => {
  const formData = new FormData();
  Object.keys(payload).forEach(key => formData.append(key, payload[key]));
  return {
    type: ADD_LOCATION,
    payload: service({
      method: "POST",
      url: "/locations",
      data: formData
    })
  }
}
export const updateLocation = (id, payload) => {
  const formData = new FormData();
  Object.keys(payload).forEach(key => formData.append(key, payload[key]));
  return {
    type: UPDATE_LOCATION,
    payload: service({
      method: "POST",
      url: "/locations/" + id,
      data: formData
    })
  }
}
export const updateLocationSuccess = (payload) => ({
  type: UPDATE_LOCATION_SUCCESS,
  payload
})

export const selectLocation = (payload, history) => ({
  type: SELECT_LOCATION,
  payload: {payload, history}
})

export const deleteLocation = (id) => ({
  type: DELETE_LOCATION,
  payload: service({
    method: "POST",
    url: "/locations/" + id,
    data: {
      '_method': 'delete'
    }
  })
})

export const deleteLocationSuccess = (payload) => ({
  type: DELETE_LOCATION_SUCCESS,
  payload
})

export const getSpecificLocation = (payload) => ({
  type: GET_SPECIFIC_LOCATION,
  payload: service.get("/locations/" + payload)
})


export const updateLocationLoading1 = (payload) => ({
  type: LOCATION_LOADING_1,
  payload
})
export const updateLocationLoading2 = (payload) => ({
  type: LOCATION_LOADING_2,
  payload
})