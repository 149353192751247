import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Form, Offcanvas, OffcanvasBody, OffcanvasHeader } from 'reactstrap';
import { Button, Radio, Space, Divider, Dropdown, Card, Upload, DatePicker, Input, Alert, message, Tooltip } from 'antd';
import moment from 'moment';
import { createLocalPost, createSchedulePost } from '../../../helpers/backend_helper';
import dayjs from 'dayjs';

const { RangePicker } = DatePicker;
const { TextArea } = Input;

function disabledDate(current) {
  // Get the current date and time with a 2-hour offset
  const now = moment().add(2, 'hours');

  // Disable all the dates and times that are before the current time
  return current && current < now;
}



const validatorOfUrl = (value) => {
  console.log(value)
  try {
    const url = new URL(value);
    if (url.protocol !== 'http:' && url.protocol !== 'https:' && url.protocol !== 'ftp:' && url.protocol !== 'file:') {
      return false
    } else {
      return true;
    }
  } catch (error) {
    return false
  }
};


class AddPost extends Component {
  static propTypes = {
    locationId: PropTypes.number
  }
  constructor(props) {
    super(props)
    this.state = {
      textAreaRef: React.createRef(),
      datePiclerRef: React.createRef(),
      errorMessage: "",
      isLoading: false,
      isOpen: this.props.isOpen,
      summary: "",
      fileList: [
      ],
      actionButton: {
        value: "CALL",
        actionValue: "",
      },
      postOptions: {
        value: "NOW",
        actionValue: "",
      },
    }
    this.setFileList.bind(this);
    this.onPreview.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.isOpen == false && this.props.isOpen == true) {
      const that = this;
      setTimeout(() => {
        that.state.textAreaRef.current?.focus();
      }, 1000)
    }
  }


  handleSubmit() {
    console.log(this.state);
    this.setState({ errorMessage: "" })

    if (this.state.summary == "") {
      this.setState({ errorMessage: "Post content required" })
      return false;
    }

    if (!this.state.fileList.length) {
      this.setState({ errorMessage: "Image is needed" })
      return false;
    }

    if (!this.props.match?.params?.id) {
      this.setState({ errorMessage: "Please Select Location First" })
      return false;
    }

    if (this.state.actionButton.value !== "CALL" && !validatorOfUrl(this.state.actionButton.actionValue)) {
      this.setState({ errorMessage: "Please enter valid Action Link" })
      document.getElementById("gmb-action-btn").click()
      return false;
    }

    this.setState({ errorMessage: "" })


    if (this.state.postOptions.value == "SCHEDULE") {
      if (!this.state.postOptions.actionValue) {
        this.state.datePiclerRef.current.focus();
        this.setState({ errorMessage: "Please select Schedule Date" })
        return false;
      }
      this.setState({ isLoading: true })
      createSchedulePost({
        locationId: this.props.match.params.id,
        actionType: this.state.actionButton.value,
        url: this.state.actionButton.actionValue,
        media: this.state.fileList[0].originFileObj,
        summary: this.state.summary,
        release_date: this.state.postOptions.actionValue
      }).then((res) => {
        console.log(res);
        this.toggleTopCanvas();
        message.success("Schedule Post Created");
      }).catch(err => {
        console.log(err)
      }).finally(() => {
        this.setState({ isLoading: false })
      })
    } else {
      this.setState({ isLoading: true })
      createLocalPost({
        locationId: this.props.match.params.id,
        actionType: this.state.actionButton.value,
        url: this.state.actionButton.actionValue,
        media: this.state.fileList[0].originFileObj,
        summary: this.state.summary
      }).then((res) => {
        console.log(res);
        this.toggleTopCanvas();
        message.success("Post Created");
      }).catch(err => {
        console.log(err)
      }).finally(() => {
        this.setState({ isLoading: false })
      })
    }
  }

  setFileList(list) {
    this.setState({
      fileList: list
    })
  }

  onFileChange({ fileList: newFileList }) {
    this.setFileList(newFileList);
  };

  async onPreview(file) {
    let src = file.url;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow?.document.write(image.outerHTML);
  };

  onChange(e) {
    console.log('radio checked', e.target.value);
    this.setState({
      actionButton: {
        actionValue: "",
        value: e.target.value
      }
    })
  };
  onChangePostOption(e) {
    this.setState({
      postOptions: {
        actionValue: "",
        value: e.target.value
      }
    })
  };

  onChangeLinkOption(e) {
    this.setState({
      actionButton: {
        ...this.state.actionButton,
        actionValue: e.target.value,
      }
    })
  };

  onSetTimeOfSchedule(e) {
    this.setState({
      postOptions: {
        ...this.state.postOptions,
        actionValue: dayjs(e).format("YYYY-MM-DD HH:mm:ss")
      }
    })
  };

  toggleTopCanvas() {
    this.props.setIsOpen(!this.props.isOpen)
  }


  render() {
    return (
      <>
        <Offcanvas

          style={{
            width: "min(90vw, 1400px)"
          }}
          isOpen={this.props.isOpen}
          className="post-add-canvas"
          direction="top"
          toggle={this.toggleTopCanvas}
        >
          <OffcanvasBody>
            <div>
              <div className="row">
                <div className="col-8">
                  <div className="post-left-section">
                    {this.state.errorMessage && (
                      <Alert message={this.state.errorMessage} type="error" className='mb-2' closable />
                    )}
                    <TextArea ref={this.state.textAreaRef} bordered={false} rows={16} placeholder="It's time to create new best post" onChange={(e) => {
                      this.setState({
                        summary: e.target.value
                      })
                    }} />
                    <Upload
                      id='post-upload'
                      className='mt-3'
                      beforeUpload={(file) => {
                        this.setState({ errorMessage: "" })
                        const reader = new FileReader();
                        reader.onload = (e) => {
                          const fileContents = e.target.result;
                          // Display the file contents or preview image here
                          // console.log(fileContents);
                        };
                        const isLt2M = file.size > 10240;
                        console.log(file.size)
                        if (!isLt2M) {
                          this.setState({ errorMessage: "Image must larger than 10kb!" })
                          message.error('Image must larger than 10kb!');
                        }
                        reader.readAsDataURL(file);
                        return false; // Prevent the file from being uploaded
                      }}
                      listType="picture-card"
                      accept="image/*"
                      fileList={this.state.fileList}
                      onChange={this.onFileChange.bind(this)}
                      onPreview={this.onPreview}
                    >
                      {console.log(this.state.fileList)}
                      {this.state.fileList.length < 5 && '+ Upload'}
                    </Upload>
                  </div>

                </div>
                <div className="col-4 p-0">
                  <div className="post-right-section">
                    <div className="post-right-section-header">
                      Publishing Options
                    </div>
                    <Radio.Group onChange={this.onChangePostOption.bind(this)} value={this.state.postOptions.value}>
                      <div className="d-flex flex-direction-column">
                        <div className="post-right-section-radio">
                          <Radio value={"NOW"}>Publish Now</Radio>
                        </div>
                        <div className="post-right-section-radio">
                          <Radio value={"SCHEDULE"}>Schedule for a Specific Date</Radio>
                        </div>
                        {this.state.postOptions.value == "SCHEDULE" && (
                          <Space direction="vertical" style={{
                            marginLeft: "30px"
                          }} size={12}>
                            <DatePicker ref={this.state.datePiclerRef} disabledDate={disabledDate} format={"YYYY-MM-DD HH:mm:ss"} showTime /* onChange={onChange} onOk={onOk} */ onOk={this.onSetTimeOfSchedule.bind(this)} />
                          </Space>
                        )}
                      </div>
                    </Radio.Group>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="post-footer">
                  <div className="post-footer-left">
                    {/*  */}
                    <Dropdown trigger={['click']} dropdownRender={() => (
                      <>
                        <Radio.Group onChange={this.onChange.bind(this)} value={this.state.actionButton.value}>
                          <Card size="small" title="Add a button to GMB Post " style={{ width: 300, display: 'block' }}>
                            <div className="d-flex flex-direction-column">
                              <div className="post-footer-left-radio">
                                <Radio value={"LEARN_MORE"}>Learn More</Radio>
                                {this.state.actionButton.value == "LEARN_MORE" && (
                                  <div className="post-footer-left-url">
                                    Link:
                                    <Input
                                      placeholder="Enter URL"
                                      status={validatorOfUrl(this.state.actionButton.actionValue) ? '' : 'error'}
                                      value={this.state.actionButton.actionValue}
                                      onChange={this.onChangeLinkOption.bind(this)}
                                    />
                                  </div>
                                )}
                              </div>
                              <div className="post-footer-left-radio">
                                <Radio value={"SIGN_UP"}>Sign Up</Radio>

                                {this.state.actionButton.value == "SIGN_UP" && (
                                  <div className="post-footer-left-url">
                                    Link:
                                    <Input
                                      status={validatorOfUrl(this.state.actionButton.actionValue) ? '' : 'error'}
                                      placeholder="Enter URL"
                                      onChange={this.onChangeLinkOption.bind(this)}
                                    />
                                  </div>
                                )}
                              </div>
                              <div className="post-footer-left-radio">
                                <Radio value={"ORDER"}>Order Online</Radio>

                                {this.state.actionButton.value == "ORDER" && (
                                  <div className="post-footer-left-url">
                                    Link:
                                    <Input
                                      status={validatorOfUrl(this.state.actionButton.actionValue) ? '' : 'error'}
                                      placeholder="Enter URL"
                                      onChange={this.onChangeLinkOption.bind(this)}
                                    />
                                  </div>
                                )}
                              </div>
                              <div className="post-footer-left-radio">
                                <Radio value={"BOOK"}>Book</Radio>
                                {this.state.actionButton.value == "BOOK" && (
                                  <div className="post-footer-left-url">
                                    Link:
                                    <Input
                                      status={validatorOfUrl(this.state.actionButton.actionValue) ? '' : 'error'}
                                      placeholder="Enter URL"
                                      onChange={this.onChangeLinkOption.bind(this)}
                                    />
                                  </div>
                                )}
                              </div>
                              <div className="post-footer-left-radio">
                                <Radio value={"SHOP"}>Buy</Radio>
                                {this.state.actionButton.value == "SHOP" && (
                                  <div className="post-footer-left-url">
                                    Link:
                                    <Input
                                      status={validatorOfUrl(this.state.actionButton.actionValue) ? '' : 'error'}
                                      placeholder="Enter URL"
                                      onChange={this.onChangeLinkOption.bind(this)}
                                    />
                                  </div>
                                )}
                              </div>
                              <div className="post-footer-left-radio">
                                <Radio value={"CALL"}>Call</Radio>
                              </div>

                            </div>
                          </Card>
                        </Radio.Group>
                      </>
                    )} placement="topLeft">
                      <Tooltip placement="top" title={"Add Button to GMB Post"}>
                        <i id="gmb-action-btn" className="font-size-20 zso-gmb-btn"></i>
                      </Tooltip>
                    </Dropdown>

                    {this.state.fileList.length < 1 && (
                      <Tooltip placement="top" title={"Attach Photo"}>
                        <i onClick={() => document.getElementById("post-upload").click()} className="font-size-20 zso-add-media"></i>
                      </Tooltip>
                    )}
                  </div>
                  <div className="post-footer-right">
                    <Space>
                      <Button type="default" shape="round" size='middle' onClick={this.toggleTopCanvas.bind(this)}>
                        Cancel
                      </Button>
                      <Button type="primary" shape="round" size='middle' onClick={this.handleSubmit.bind(this)} loading={this.state.isLoading}>
                        Post
                      </Button>
                    </Space>
                  </div>
                </div>
              </div>
            </div>
          </OffcanvasBody>
        </Offcanvas>
      </>
    )
  }
}

AddPost.propTypes = {
  locationId: PropTypes.number
}

const mapStateToProps = ({ Location }) => {
  return ({
  })
};

const mapDispatchToProps = dispatch => ({
  // selectLocation: (id, history) => dispatch(selectLocation(id, history)),
});

export default (withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(AddPost)));
