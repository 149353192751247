import { message } from "antd";
import { GET_LOCATIONS_SUCCESS, GET_LOCATIONS_FAIL, GET_LOCATIONS, UPDATE_LOCATION_SUCCESS, DELETE_LOCATION_SUCCESS, LOCATION_LOADING_1, LOCATION_LOADING_2, SELECT_LOCATION } from "./actionTypes"

const INIT_STATE = {
  locations: [
  ],
  selectedLocation: {},
  isSelectedLocation: false,
  single: {},
  isLoading: false,
  isUpdateLoading: false,
  loading1: false,
  loading2: false,
  sort: {},
  error: {},
}

const locations = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_LOCATIONS_SUCCESS:
      const data = action.payload;
      return {
        ...state,
        isLoading: false,
        locations: data,
        error: {},
      }

    case GET_LOCATIONS_FAIL:
      message.error(action.payload.message || 'Something went wrong')
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      }

    case UPDATE_LOCATION_SUCCESS:
      return {
        ...state,
        single: action.payload,
      }

    case SELECT_LOCATION:
      const pay = action.payload.payload
      const history = action.payload.history
      let loc = state.locations.find(loc => pay == loc.id)
      let foundId = true;
      if(!loc && state.locations.length) {
        loc = state.locations[0];
        foundId = false
      }
      if(!foundId) {
        history?.push("/"+loc.id+"/dashboard")
      }
      return {
        ...state,
        selectedLocation: loc || {},
        isSelectedLocation: loc || false,
      }

    case DELETE_LOCATION_SUCCESS:
      console.log("DELETE LOCATION")
      return {
        ...state,
        locations: [...state.locations.filter(i => i.id != action.payload.id)]
      }

    case LOCATION_LOADING_1:
      return {
        ...state,
        loading1: action.payload
      }

    case LOCATION_LOADING_2:
      return {
        ...state,
        loading2: action.payload
      }


    case GET_LOCATIONS:
      return {
        ...state,
        error: {},
        isLoading: true
      }


    default:
      return state
  }
}

export default locations
