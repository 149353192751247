import { message } from "antd";
import { GET_WEBINARS_SUCCESS, GET_WEBINARS_FAIL, GET_WEBINARS, UPDATE_WEBINAR_SUCCESS, DELETE_WEBINAR_SUCCESS, WEBINAR_LOADING_1, WEBINAR_LOADING_2 } from "./actionTypes"

const INIT_STATE = {
  list: [
  ],
  single: {},
  pagination: {
    pageSize: 5,
    current: 0,
    total: 0,
  },
  isLoading: false,
  isUpdateLoading: false,
  loading1: false,
  loading2: false,
  sort: {},
  error: {},
}

const webinars = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_WEBINARS_SUCCESS:
      const data = action.payload;
      return {
        ...state,
        isLoading: false,
        list: data?.data,
        error: {},
        pagination: {
          pageSize: data?.per_page,
          current: data?.current_page,
          total: data?.total,
        }
      }

    case GET_WEBINARS_FAIL:
      message.error(action.payload.message || 'Something went wrong')
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      }

    case UPDATE_WEBINAR_SUCCESS:
      return {
        ...state,
        single: action.payload,
      }

    case DELETE_WEBINAR_SUCCESS:
      console.log("DELETE WEBINAR")
      return {
        ...state,
        list: [...state.list.filter(i => i.id != action.payload.id)]
      }

    case WEBINAR_LOADING_1:
      return {
        ...state,
        loading1: action.payload
      }

    case WEBINAR_LOADING_2:
      return {
        ...state,
        loading2: action.payload
      }


    case GET_WEBINARS:
      return {
        ...state,
        error: {},
        isLoading: true
      }


    default:
      return state
  }
}

export default webinars
