import React, { Component, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Breadcrumb, BreadcrumbItem, Button, Col, Input, Modal, Row } from "reactstrap";
import { GoogleLogin, useGoogleLogin, GoogleOAuthProvider } from '@react-oauth/google';
import axios from "axios";
import { SET_ME } from "../../store/me/actionTypes";
import { connect, useDispatch } from "react-redux";
import { updateGMBToken } from "../../helpers/backend_helper";

function setCookie(cname, cvalue, time = 1) {
  const d = new Date();
  d.setTime(d.getTime() + (time * 1000));
  let expires = "expires=" + d.toUTCString();
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

// import gapi from 'gapi';

const SCOPE = "https://www.googleapis.com/auth/business.manage email https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/drive.readonly";
const CLIENT_ID = "476931944629-rksefil5mr0li8c58ajv78pc7t6u84o5.apps.googleusercontent.com";

function withMyHook(Component) {

  return function WrappedComponent(props) {
    const [isSignedIn, setIsSignedIn] = useState(false);
    const [userInformation, setUserInformation] = useState({
      email: ""
    })

    const dispatch = useDispatch();

    const handleSuccess = async (token) => {
      setCookie('tokenInfo', token.access_token, 1000000000);
      await updateGMBToken(token.access_token)
      axios
        .get('https://people.googleapis.com/v1/people/me?personFields=emailAddresses', {
          headers: {
            Authorization: `Bearer ${token.access_token}`,
          },
        })
        .then((res) => {
          setUserInformation({
            email: res.data.emailAddresses[0].value
          });
          dispatch({
            type: SET_ME,
            payload: {
              accessToken: token.access_token,
              gmbEmail: res.data.emailAddresses[0].value
            }
          })

          setIsSignedIn(true);
        })
        .catch((error) => {
          console.error(error);
        });
    };

    const login = useGoogleLogin({
      // flow: "auth-code",
      flow: "implicit",
      onSuccess: handleSuccess,
      scope: SCOPE
    });



    return <Component {...props} login={login} user={userInformation} isSignedIn={isSignedIn} />;
  }
}



class GoogleLoginModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      subscribemodal: false,
    }

    // gapi.load('client:auth2', function () {
    //   gapi.auth2.init({
    //     client_id: CLIENT_ID,
    //     scope: SCOPE
    //   });
    // });

    this.togglesubscribemodal.bind(this);
  }

  componentDidMount() {
    setTimeout(() => this.setState({ subscribemodal: true }), 0);
  }

  loginWithGapi() {
    gapi.auth2.getAuthInstance().signIn().then(function (googleUser) {
      // 3. Extract the ID token and refresh token from the response
      var idToken = googleUser.getAuthResponse().id_token;
      var refreshToken = googleUser.getAuthResponse().refresh_token;
      console.log('ID token: ', idToken);
      console.log('Refresh token: ', refreshToken);
    });
  }

  togglesubscribemodal = () => {
    this.setState(prevState => ({
      subscribemodal: !prevState.subscribemodal,
    }));
  };

  render() {
    return (
      <React.Fragment>
        {!this.props.accessToken ? (
          <Modal
            isOpen={this.state.subscribemodal}
            role="dialog"
            backdrop="static"
            autoFocus={true}
            data-toggle="modal"
            centered
            toggle={this.togglesubscribemodal}
          >
            <div className="modal-content">
              <div className="modal-header border-bottom-0">
                <button
                  type="button"
                  className="btn-close d-none"
                  onClick={() => this.setState({ subscribemodal: false })}
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                <div className="text-center mb-4">
                  <div className="avatar-md mx-auto mb-4">
                    <div className="avatar-title bg-light  rounded-circle text-primary h1">
                      <i className="mdi mdi-google"></i>
                    </div>
                  </div>

                  <div className="row justify-content-center">
                    <div className="col-xl-10">
                      <h4 className="text-primary">Login with Google !</h4>
                      {/* <p className="text-muted font-size-14 mb-4">
                      Login with Google
                    </p> */}

                      <Button color="primary" type="button" id="button-addon2" onClick={this.props.login}>
                        <i className="mdi mdi-google"></i> Google Login
                      </Button>
                      <div className="input-group  rounded bg-light">
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal>
        ) : (
          <>
            {this.props.children}
          </>
        )}

      </React.Fragment>
    )
  }
}

GoogleLoginModal.propTypes = {
  login: PropTypes.any
}

const mapStateToProps = ({ Me }) => {
  console.log("ME", Me)
  return ({
    accessToken: Me.accessToken,
  })
};

const mapDispatchToProps = dispatch => ({
  onGetChartsData: periodType => dispatch(getChartsData(periodType)),
});

export default withMyHook(connect(
  mapStateToProps,
  mapDispatchToProps
)(GoogleLoginModal));
// export default GoogleLoginModal
