import service from "../../helpers/Api/FetchInterceptor"
import { ADD_BANNER, BANNER_LOADING_1, BANNER_LOADING_2, BANNER_TEST, DELETE_BANNER, DELETE_BANNER_SUCCESS, GET_BANNERS, GET_BANNERS_FAIL, GET_BANNERS_SUCCESS, GET_SPECIFIC_BANNER, UPDATE_BANNER, UPDATE_BANNER_SUCCESS } from "./actionTypes"

export const getBanners = (payload) => ({
  type: GET_BANNERS,
  payload: payload
})

export const getBannerSuccess = banners => ({
  type: GET_BANNERS_SUCCESS,
  payload: banners,
})

export const getBannerFail = error => ({
  type: GET_BANNERS_FAIL,
  payload: error,
})

export const addBanner = (payload) => {
  const formData = new FormData();
  Object.keys(payload).forEach(key => formData.append(key, payload[key]));
  return {
    type: ADD_BANNER,
    payload: service({
      method: "POST",
      url: "/banners",
      data: formData
    })
  }
}
export const updateBanner = (id, payload) => {
  const formData = new FormData();
  Object.keys(payload).forEach(key => formData.append(key, payload[key]));
  return {
    type: UPDATE_BANNER,
    payload: service({
      method: "POST",
      url: "/banners/" + id,
      data: formData
    })
  }
}
export const updateBannerSuccess = (payload) => ({
  type: UPDATE_BANNER_SUCCESS,
  payload
})

export const deleteBanner = (id) => ({
  type: DELETE_BANNER,
  payload: service({
    method: "POST",
    url: "/banners/" + id,
    data: {
      '_method': 'delete'
    }
  })
})

export const deleteBannerSuccess = (payload) => ({
  type: DELETE_BANNER_SUCCESS,
  payload
})

export const getSpecificBanner = (payload) => ({
  type: GET_SPECIFIC_BANNER,
  payload: service.get("/banners/" + payload)
})


export const updateBannerLoading1 = (payload) => ({
  type: BANNER_LOADING_1,
  payload
})
export const updateBannerLoading2 = (payload) => ({
  type: BANNER_LOADING_2,
  payload
})

// export const getTest = (payload) => ({
//   type: BANNER_TEST,
//   payload: payload
// })

export const getTest = (payload) => {
  return ({
    type: BANNER_TEST,
    payload: service.get("/banners")
    // payload: payload
  })
}
