import service from "./Api/FetchInterceptor";
import { CREATE_LOCAL_POSTS, CREATE_SCHEDULE_POSTS, DELETE_LOCAL_POSTS, FETCH_LOCAL_POSTS, FETCH_NEW_LOCATIONS, FETCH_REVIEWS, GET_DASHBOARD_INSIGHT, GET_DASHBOARD_INSIGHT_DATE_WISE, GET_SAVED_LOCATION, GET_UNAUTH_DASHBOARD_INSIGHT_DATE_WISE, SAVE_NEW_LOCATIONS, UPDATE_TOKEN } from "./url_helper";

Array.prototype.contains = function (obj) {
    var i = this.length;
    while (i--) {
        if (this[i] === obj) {
            return true;
        }
    }
    return false;
}

export const updateGMBToken = token => service.post(UPDATE_TOKEN, {
    token: token,
    // refresh_token: token 
})

export const saveNewLocation = name => service.post(SAVE_NEW_LOCATIONS, {
    name: name,
})

export const getDashboardInsides = id => id && service.get(GET_DASHBOARD_INSIGHT + "?id=" + id).then(res => res.data);
export const getDashboardInsidesDateWise = (id, sDate, eDate) => id && service.post(GET_DASHBOARD_INSIGHT_DATE_WISE + "?id=" + id, {
    s_date: sDate,
    e_date: eDate
}).then(res => res.data);

export const getUnAuthDashboardInsidesDateWise = (locationID, id, sDate, eDate) => id && service.post(GET_UNAUTH_DASHBOARD_INSIGHT_DATE_WISE + "/" + id + "?id=" + locationID, {
    s_date: sDate,
    e_date: eDate
}).then(res => res.data);

export const fetchNewLocations = (pageSize, token = "") => service.get(`${FETCH_NEW_LOCATIONS}?pageSize=${pageSize}&nextPageToken=${token}`).then(res => res.data);
export const fetchLocationReview = (ID, pageSize, token = "") => service.get(`${FETCH_REVIEWS}?id=${ID}&pageSize=${pageSize}&nextPageToken=${token}`).then(res => res.data);
export const fetchLocationLocalPost = (ID, pageSize, token = "") => service.get(`${FETCH_LOCAL_POSTS}?id=${ID}&pageSize=${pageSize}&nextPageToken=${token}`).then(res => res.data);

export const reviewReply = ({
    location_id,
    review_id,
    comment
}) => service.post(`/reply?id=${location_id}`, {
    id: location_id,
    review_id,
    comment
}).then(res => res.data);
export const allocateUserToLocation = (user_id, saved_location_id) => service.post(`/user_location`, { user_id, saved_location_id }).then(res => res.data);
export const deleteLocalPost = (name) => service.post(`${DELETE_LOCAL_POSTS}`, { name: name }).then(res => res.data);
export const updateLocalPost = ({
    name, summary
}) => service.post(`${"/update_localpost"}`, { name, summary }).then(res => res.data);
export const deleteSchedulePost = (id) => service.delete(`${CREATE_SCHEDULE_POSTS}/${id}`).then(res => res.data);
export const createLocalPost = ({
    locationId = "",
    summary = "",
    actionType = "",
    topicType = "STANDARD",
    mediaFormat = "PHOTO",
    url = "",
    media = "",
}) => {
    let formData = new FormData();
    if (["CALL", "BOOK", "SHOP", "LEARN_MORE", "SIGN_UP", "ORDER"].contains(actionType)) {
        formData.append("actionType", actionType)
    } else {
        return Promise.reject("Not valid actionType")
    }
    formData.append("summary", summary)
    formData.append("topicType", topicType)
    formData.append("mediaFormat", mediaFormat)
    if (actionType != "CALL")
        formData.append("url", url)
    formData.append("media", media)
    return service({
        method: "POST",
        url: `${CREATE_LOCAL_POSTS}?id=${locationId}`,
        data: formData
    })
};
export const createSchedulePost = ({
    locationId = "",
    summary = "",
    actionType = "",
    topicType = "STANDARD",
    mediaFormat = "PHOTO",
    url = "",
    media = "",
    release_date = "",
}) => {
    let formData = new FormData();
    if (["CALL", "BOOK", "SHOP", "LEARN_MORE", "SIGN_UP", "ORDER"].contains(actionType)) {
        formData.append("actionType", actionType)
    } else {
        return Promise.reject("Not valid actionType")
    }
    formData.append("saved_location_id", locationId)
    formData.append("summary", summary)
    formData.append("topicType", topicType)
    formData.append("mediaFormat", mediaFormat)
    formData.append("release_date", release_date)
    if (actionType != "CALL")
        formData.append("url", url)
    formData.append("mediaUrl", media)
    return service({
        method: "POST",
        url: `${CREATE_SCHEDULE_POSTS}`,
        data: formData
    })
};

export const getSavedLocations = () => {
    return service.get(GET_SAVED_LOCATION)
        .then(res => {
            console.log(res.data)
            return res.data.location_list
        })
}

// export const getSpecificReport = (id) => {
//     return service.get
// }