import React from "react"
import { Redirect } from "react-router-dom"
// User profile
import UserProfile from "../pages/Authentication/UserProfile"

// Authentication related pages
import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"
import Register from "../pages/Authentication/Register"
import ForgetPwd from "../pages/Authentication/ForgetPassword"

// Pages
import Dashboard from "../pages/Dashboard/index"
import Banners from "../pages/Banners"
import BannerOperations from "../pages/Banners/BannerOperations"
import Sponsers from "../pages/Sponsers"
import SponserOperations from "../pages/Sponsers/SponserOperations"
import Webinars from "../pages/Webinars"
import WebinarOperations from "../pages/Webinars/WebinarOperations"
import Notifications from "../pages/Notifications"
import NotificationOperations from "../pages/Notifications/NotificationOperations"
import GoogleLogin from "../pages/GoogleLogin"
import Locations from "../pages/Locations/CustomReport"
import NormalReports from "../pages/Locations/NormalReport"
import Users from "../pages/Users"
import Reviews from "../pages/Locations/Reviews"
import ReviewsNew from "../pages/Locations/ReviewsNew"
import Posts from "../pages/Locations/posts"
import PdfPrint from "../pages/pdfPrint"
import UsersOperations from "pages/Users/UsersOperations"

const authProtectedRoutes = [
  { path: "/dashboard", component: Dashboard },

  { path: "/:id/dashboard", exact: false, component: NormalReports},
  { path: "/:id/custom-report/:reportId", exact: false, component: Locations},
  { path: "/:id/reviews", component: ReviewsNew},
  { path: "/:id/posts", component: Posts},

  { path: "/users", component: Users},
  { path: "/users/add", component: (props) => <UsersOperations type={'ADD'} />},
  { path: "/users/:userId/edit", component: (props) => <UsersOperations type={'EDIT'} />},

  { path: "/banners", component: Banners},
  { path: "/banners/add", component: (props) => <BannerOperations type={'ADD'} />},
  { path: "/banners/:id/edit", component: (props) => <BannerOperations type={'EDIT'} />},
  
  { path: "/sponsers", component: Sponsers},
  { path: "/sponsers/add", component: (props) => <SponserOperations type={'ADD'} />},
  { path: "/sponsers/:id/edit", component: (props) => <SponserOperations type={'EDIT'} />},

  { path: "/webinars", component: Webinars},
  { path: "/webinars/add", component: (props) => <WebinarOperations type={'ADD'} />},
  { path: "/webinars/:id/edit", component: (props) => <WebinarOperations type={'EDIT'} />},
  
  { path: "/notifications", component: Notifications},
  { path: "/notifications/add", component: (props) => <NotificationOperations type={'ADD'} />},
  { path: "/notifications/:id/edit", component: (props) => <NotificationOperations type={'EDIT'} />},


  { path: "/google-login", component: (props) => <GoogleLogin type={'EDIT'} />},

  //profile
  { path: "/profile", component: UserProfile },

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  { path: "/", exact: true, component: () => <Redirect to="/dashboard" /> }
]

const publicRoutes = [
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/forgot-password", component: ForgetPwd },
  { path: "/register", component: Register },
  { path: "/unauthreports/:locationId/:id",  component: PdfPrint},
  { path: "/unauthreports-dates/:locationId/:id/:startDate/:endDate",  component: PdfPrint},
]

export { authProtectedRoutes, publicRoutes }
