import service from "../../helpers/Api/FetchInterceptor"
import { ADD_WEBINAR, WEBINAR_LOADING_1, WEBINAR_LOADING_2, WEBINAR_TEST, DELETE_WEBINAR, DELETE_WEBINAR_SUCCESS, GET_WEBINARS, GET_WEBINARS_FAIL, GET_WEBINARS_SUCCESS, GET_SPECIFIC_WEBINAR, UPDATE_WEBINAR, UPDATE_WEBINAR_SUCCESS } from "./actionTypes"

export const getWebinars = (payload) => ({
  type: GET_WEBINARS,
  payload: payload
})

export const getWebinarSuccess = webinars => ({
  type: GET_WEBINARS_SUCCESS,
  payload: webinars,
})

export const getWebinarFail = error => ({
  type: GET_WEBINARS_FAIL,
  payload: error,
})

export const addWebinar = (payload) => {
  const formData = new FormData();
  Object.keys(payload).forEach(key => formData.append(key, payload[key]));
  return {
    type: ADD_WEBINAR,
    payload: service({
      method: "POST",
      url: "/webinars",
      data: formData
    })
  }
}
export const updateWebinar = (id, payload) => {
  const formData = new FormData();
  Object.keys(payload).forEach(key => formData.append(key, payload[key]));
  return {
    type: UPDATE_WEBINAR,
    payload: service({
      method: "POST",
      url: "/webinars/" + id,
      data: formData
    })
  }
}
export const updateWebinarSuccess = (payload) => ({
  type: UPDATE_WEBINAR_SUCCESS,
  payload
})

export const deleteWebinar = (id) => ({
  type: DELETE_WEBINAR,
  payload: service({
    method: "POST",
    url: "/webinars/" + id,
    data: {
      '_method': 'delete'
    }
  })
})

export const deleteWebinarSuccess = (payload) => ({
  type: DELETE_WEBINAR_SUCCESS,
  payload
})

export const getSpecificWebinar = (payload) => ({
  type: GET_SPECIFIC_WEBINAR,
  payload: service.get("/webinars/" + payload)
})


export const updateWebinarLoading1 = (payload) => ({
  type: WEBINAR_LOADING_1,
  payload
})
export const updateWebinarLoading2 = (payload) => ({
  type: WEBINAR_LOADING_2,
  payload
})