/* TASKS */
export const WEBINAR_LOADING_1 = 'WEBINAR_LOADING_1'
export const WEBINAR_LOADING_2 = 'WEBINAR_LOADING_2'
export const GET_WEBINARS = 'GET_WEBINARS'
export const GET_WEBINARS_SUCCESS = 'GET_WEBINARS_SUCCESS'
export const GET_WEBINARS_FAIL = 'GET_WEBINARS_FAIL'
export const ADD_WEBINAR = 'ADD_WEBINAR'
export const GET_SPECIFIC_WEBINAR = 'GET_SPECIFIC_WEBINAR'
export const UPDATE_WEBINAR = 'UPDATE_WEBINAR'
export const UPDATE_WEBINAR_SUCCESS = 'UPDATE_WEBINAR_SUCCESS'
export const DELETE_WEBINAR = 'DELETE_WEBINAR'
export const DELETE_WEBINAR_SUCCESS = 'DELETE_WEBINAR_SUCCESS'
export const WEBINAR_TEST = 'WEBINAR_TEST'
