import { SET_ME } from "./actionTypes";

function getCookie(cname) {
  let name = cname + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(';');
  for(let i = 0; i <ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

const initialState = {
  user: {
    name: "",
  },
  // accessToken: "ya29.a0AVvZVsobyf7bJJ6kLB_Ov-X0qxk6eKSJa9EM3uOZXPvJYgZFybnEgNmFVC_aHo3Y8YS1WVi1vorSWBIjmfO4gs2rfchkV5SZqNAqCMFaWZEwUn9J1srg2zxWpe2hSaNh5qu45DH9Y4vI_OZ3kf0wFnIzK7X1GgaCgYKATcSARISFQGbdwaI1YBDjImxCEFRjoYYfBclhQ0165",
  // accessToken: getCookie('tokenInfo'),
  accessToken: true,
  gmbEmail: "info.kktechsolution@gmail.com"
};

const me = (state = initialState, action) => {
  switch (action.type) {
    case SET_ME:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};

export default me;