import service from "../../helpers/Api/FetchInterceptor"
import { ADD_NOTIFICATION, NOTIFICATION_LOADING_1, NOTIFICATION_LOADING_2, NOTIFICATION_TEST, DELETE_NOTIFICATION, DELETE_NOTIFICATION_SUCCESS, GET_NOTIFICATIONS, GET_NOTIFICATIONS_FAIL, GET_NOTIFICATIONS_SUCCESS, GET_SPECIFIC_NOTIFICATION, UPDATE_NOTIFICATION, UPDATE_NOTIFICATION_SUCCESS } from "./actionTypes"

export const getNotifications = (payload) => ({
  type: GET_NOTIFICATIONS,
  payload: payload
})

export const getNotificationSuccess = notifications => ({
  type: GET_NOTIFICATIONS_SUCCESS,
  payload: notifications,
})

export const getNotificationFail = error => ({
  type: GET_NOTIFICATIONS_FAIL,
  payload: error,
})

export const addNotification = (payload) => {
  const formData = new FormData();
  Object.keys(payload).forEach(key => formData.append(key, payload[key]));
  return {
    type: ADD_NOTIFICATION,
    payload: service({
      method: "POST",
      url: "/notifications",
      data: formData
    })
  }
}
export const updateNotification = (id, payload) => {
  const formData = new FormData();
  Object.keys(payload).forEach(key => formData.append(key, payload[key]));
  return {
    type: UPDATE_NOTIFICATION,
    payload: service({
      method: "POST",
      url: "/notifications/" + id,
      data: formData
    })
  }
}
export const updateNotificationSuccess = (payload) => ({
  type: UPDATE_NOTIFICATION_SUCCESS,
  payload
})

export const deleteNotification = (id) => ({
  type: DELETE_NOTIFICATION,
  payload: service({
    method: "POST",
    url: "/notifications/" + id,
    data: {
      '_method': 'delete'
    }
  })
})

export const deleteNotificationSuccess = (payload) => ({
  type: DELETE_NOTIFICATION_SUCCESS,
  payload
})

export const getSpecificNotification = (payload) => ({
  type: GET_SPECIFIC_NOTIFICATION,
  payload: service.get("/notifications/" + payload)
})


export const updateNotificationLoading1 = (payload) => ({
  type: NOTIFICATION_LOADING_1,
  payload
})
export const updateNotificationLoading2 = (payload) => ({
  type: NOTIFICATION_LOADING_2,
  payload
})