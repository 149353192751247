import { useState } from "react";

function withRerender(Component) {
    return function WrapElement(props) {

        const [key, setKey] = useState(Math.random);

        function rerender() {
            setKey(Math.random)
        }
        return <Component key={key} {...props} rerender={rerender} />
    }
}

export default withRerender;
