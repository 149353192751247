import React, { Component, useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Container,
  Card,
  CardBody,
  Col,
  Row,
  CardHeader,
  Offcanvas,
  OffcanvasHeader,
  OffcanvasBody,
} from "reactstrap";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import SimpleBar from "simplebar-react";
import { withRouter } from "react-router";
import { useHistory } from "react-router-dom";


//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

//i18n
import { withTranslation } from "react-i18next";
import classNames from "classnames";

// Import FecthAPI
import { Button, Checkbox, Input, Menu, message, Modal, Popconfirm, Tooltip, } from "antd";
import { DeleteOutlined, EditFilled, PlusCircleOutlined, LoadingOutlined } from '@ant-design/icons';
import { addCardToReport, createCustomReport, deleteCardToReport, deleteCustomReport, fetchCustomReports, selectCustomReport, updateReportSchedule } from "../../store/customCard";
import { history } from "../../App";
import { cardEnum } from "./reports/CardConstant";


function getItem(item, label, key, icon, children, type) {
  return {
    item,
    key,
    icon,
    children,
    label,
    type,
  };
}

// submenu keys of first level
const rootSubmenuKeys = ['sub1', 'sub2', 'sub3'];
const MenuComponent = (props) => {
  // const [openKeys, setOpenKeys] = useState(['sub1']);
  const setOpenKeys = props.setOpenKeys
  const openKeys = props.openKeys
  let historyX = useHistory();
  const onOpenChange = (keys) => {
    const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
    if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      setOpenKeys(keys);
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    }
  };
  return (
    <>
      {/* <Menu
        mode="inline"
        openKeys={props.openKeys}
        defaultSelectedKeys={props.openKeys}
        onOpenChange={onOpenChange}
        onClick={function ({ item, key, keyPath, domEvent }) {
          historyX.push(`/${props.LocationID}/custom-report/${item.props.item.id}`)
          console.log("CALLED=>");
          setOpenKeys(key)
        }}
        items={props.menus.map((i, index) => getItem(i, i.name, index))}
      >
      </ Menu> */}

      <div className="cst-menu-body">
        {props.menus.map((i, index) => (
          <div className={`cst-menu-body-item ${props.openKeys == index ? 'active' : ''}`} key={i.id} onClick={function () {
            historyX.push(`/${props.LocationID}/custom-report/${i.id}`)
            // setOpenKeys(key)
          }}>
            {i.name}
          </div>
        ))}
      </div>

    </>
  );
};

const SUMMARY = "sub1";
const QA = "sub2";
const PERFORMANCE = "sub3"
const CUSTOM_REPORT = "sub4"

const REPORT = {
  SEARCH: { name: "Search Impression" },
  MAP: { name: "Map Impression" },
  VISITOR: { name: "Visitor Actions" },
  CONVERSATION: { name: "Conversation" },
  R_Q_SUMMARY: { name: "Reviews & Questions Summary" },
  PERFORMANCE_SUMMARY: { name: "Performance Summary" },
  REVIEW_GROWTH: { name: "Review Growth" },
  QUESTION_GROWTH: { name: "Question Growth" },
  ALL_SUMARY: { name: "All Insides cards" },
}

const dateFormat = 'YYYY-MM-DD';

class CustomReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      inputRef: React.createRef(),
      openKeys: '0',
      cardsCanvasOpen: false,
      updateNameModal: false,
      updatedCardName: "",

      createDashboardModal: false,
      createDashboardName: "",
    };

    this.toggleCustomizeCards = this.toggleCustomizeCards.bind(this);
    this.addCardToPatch = this.addCardToPatch.bind(this);
    this.deleteCardToPatch = this.deleteCardToPatch.bind(this);
    this.deleteReportCard = this.deleteReportCard.bind(this);
    this.cardList = this.cardList.bind(this)
    this.renameReport = this.renameReport.bind(this);
    this.createReport = this.createReport.bind(this);

    this.ONCE_OPEN_KEY = false
  }

  async componentDidMount() {
    if (!this.props.customReports.length) {
      // this.props.fetchCustomReports(this.props.ID)
    }
    this.handleOpenMenu()

  }

  componentDidUpdate(prevProps, prevState) {
    // if (this.prevProps.id == )
    this.handleOpenMenu(prevProps, prevState)
    // updateNameModal
    if ((this.state.createDashboardModal && !prevState.createDashboardModal) || (this.state.updateNameModal && !prevState.updateNameModal)) {
      console.log("OPPEN", this.state.createDashboardModal, prevState.createDashboardModal);
      const that = this;
      setTimeout(() => {
        that.state.inputRef.current.select();
      }, 100)
    }
  }

  handleOpenMenu(prevProps = {}, prevState = {}) {
    const reportID = this.props.match?.params?.reportId || 0
    this.props.selectCustomReport(reportID);
    if (this.props.customReports.length && !this.ONCE_OPEN_KEY) {
      console.log("Report ID", reportID)
      const that = this;
      const index = this.props.customReports.findIndex(i => i.id == reportID)
      if (index != -1) {
        this.setOpenKeys(index);
        console.log("CALLED=>2");
      }
      else {
        this.setOpenKeys('0');
        console.log("CALLED=>3");
        this.props.history.push(`/${this.props.ID}/custom-report/${this.props.customReports[0].id}`)
      }
      this.ONCE_OPEN_KEY = true
    }
  }


  setOpenKeys(openKeys) {
    this.setState({ openKeys })
  }


  async toggleCustomizeCards() {
    this.setState({ cardsCanvasOpen: !this.state.cardsCanvasOpen });
  }

  cardList() {
    const allCards = Object.entries(REPORT).map(i => ({
      ...i[1],
      key: i[0]
    }));

    const currentPageCards = this.props.currentReport?.cards || [];

    let selectedCard = allCards.filter(i => {
      return currentPageCards.find(item => item.name == i.key)
    })
    selectedCard = selectedCard.map(i => ({ ...i, selected: true }));

    let unSelectedCard = allCards.filter(i => {
      return !currentPageCards.find(item => item.name == i.key)
    })
    unSelectedCard = unSelectedCard.map(i => ({ ...i, selected: false }));

    return {
      selected: selectedCard,
      unSelected: unSelectedCard,
      sort: [...unSelectedCard, ...selectedCard.reverse()]
    };

  }

  addCardToPatch(newCard) {
    const patchData = {
      id: this.props.currentReport.id,
      name: this.props.currentReport.name,
      mail_body: this.props.currentReport.mail_body || "",
      is_scheduled: this.props.currentReport.is_scheduled ? 1 : 0,
      card_ids: [...this.props.currentReport.cards.map(i => cardEnum(i.name)), cardEnum(newCard.key)],
      emails: this.props.currentReport.emails.map(i => i.email)
    }
    return patchData;
  }
  deleteCardToPatch(newCard) {
    const patchData = {
      id: this.props.currentReport.id,
      name: this.props.currentReport.name,
      mail_body: this.props.currentReport.mail_body || "",
      is_scheduled: this.props.currentReport.is_scheduled ? 1 : 0,
      card_ids: [...this.props.currentReport.cards.filter(i => i.name != newCard.key).map(i => cardEnum(i.name))],
      emails: this.props.currentReport.emails.map(i => i.email)
    }
    return patchData;
  }

  async deleteReportCard() {
    return new Promise(async (resolve, rejet) => {
      try {
        const result = await this.props.deleteCustomReport(this.props.currentReport.id);
        message.success("Card deleted");
        this.props.history.push(`/${this.props.ID}/custom-report/0}`)
        resolve(true)
      } catch {
        rejet(false)
      }
    })

  }

  async createReport() {
    const patchData = {
      saved_location_id: this.props.ID,
      name: this.state.createDashboardName || "Untitled Dashboard",
      mail_body: "",
      is_scheduled: 0,
      card_ids: [],
      emails: []
    }

    const result = await this.props.createCustomReport({
      id: this.props.reportId,
      data: patchData
    })

    if (result.payload?.custom_report) {
      message.success("New Card Created")
      this.setState({
        createDashboardModal: !this.state.createDashboardModal
      })
      this.props.history.push(`/${this.props.ID}/custom-report/${result.payload?.custom_report?.id || 0}}`)
    } else {
      console.log("SOMTHING WRONGG", result)
    }

    console.log("SUBMIT REQUEST: ", patchData, result);
  }

  async renameReport() {
    const patchData = {
      name: this.state.updatedCardName || this.props.currentReport.name,
      mail_body: this.props.currentReport.mail_body || "",
      is_scheduled: this.props.currentReport.is_scheduled ? 1 : 0,
      card_ids: this.props.currentReport.cards.map(i => cardEnum(i.name)),
      emails: this.props.currentReport.emails.map(i => i.email)
    }

    const result = await this.props.updateReportSchedule({
      id: this.props.reportId,
      data: patchData
    })

    if (result.payload?.custom_report) {
      message.success("Card name updated")
      this.setState({
        updateNameModal: !this.state.updateNameModal
      })
    } else {
      console.log("SOMTHING WRONGG", result)
    }

    console.log("SUBMIT REQUEST: ", patchData, result);
  }


  render() {
    //meta title
    document.title = "CustomReport";

    return (
      <React.Fragment>

        <div className="row">
          <div className="col-2 sub-navigation">
            <div className="sub-navigation-container">
              {(this.props.customReportsIsLoading) ? (
                <>
                  <div className="d-flex justify-content-center overflow-hidden mt-3">
                    <LoadingOutlined style={{
                      fontSize: "25px",
                    }} />
                  </div>
                </>
              ) : (
                <>
                  <div className="cst-menu">
                    <div className="cst-menu-header">
                      <div className="cst-menu-header-text">DASHBOARD</div>
                      <span className="cst-menu-header-icon" onClick={(() => {
                        this.setState({
                          createDashboardName: ''
                        })
                        this.setState({
                          createDashboardModal: !this.state.createDashboardModal
                        })
                      }).bind(this)}>
                        <Tooltip placement="top" title={"Create Dashboard"}>
                          <PlusCircleOutlined />
                        </Tooltip>
                      </span>
                      <Modal
                        title="Create Dashboard"
                        open={this.state.createDashboardModal}
                        okText="Create"
                        onOk={this.createReport}
                        // confirmLoading={confirmLoading}
                        onCancel={(() => {
                          this.setState({
                            createDashboardModal: !this.state.createDashboardModal
                          })
                        }).bind(this)}
                      >
                        <div className="mt-3 mb-3">
                          <Input ref={this.state.inputRef} defaultValue={"Untitled Dashboard"} onPressEnter={this.createReport } onChange={(e => this.setState({
                            createDashboardName: e.target.value
                          })).bind(this)} />
                        </div>
                      </Modal>
                    </div>
                  </div>
                  <MenuComponent LocationID={this.props.ID} menus={this.props.customReports} openKeys={this.state.openKeys} setOpenKeys={this.setOpenKeys.bind(this)} />
                </>
              )}
            </div>
          </div>
          <div className="col-2">

          </div>
          <div className="col-10 sub-content">
            <div>
              {this.props.customReports.length ? (
                <Row>
                  <div className="col-12">
                    <div className="custom-card-header mb-3">
                      <h3 className="mb-0">
                        {this.props.currentReport.name}
                        <Tooltip placement="top" title={"Edit Report Name"}>
                          <span className="custom-card-name-edit" onClick={(() => {
                            this.setState({
                              updateNameModal: !this.state.updateNameModal
                            })
                          }).bind(this)}>
                            <EditFilled />
                          </span>
                        </Tooltip>
                        <Modal
                          title="Rename Custom Report Name"
                          open={this.state.updateNameModal}
                          okText="Update"
                          onOk={this.renameReport}
                          // confirmLoading={confirmLoading}
                          onCancel={(() => {
                            this.setState({
                              updateNameModal: !this.state.updateNameModal
                            })
                          }).bind(this)}
                        >
                          <div className="mt-3 mb-3">
                            <Input ref={this.state.inputRef} defaultValue={this.props.currentReport.name} onPressEnter={this.renameReport} onChange={(e => this.setState({
                              updatedCardName: e.target.value
                            })).bind(this)} />
                          </div>
                        </Modal>
                      </h3>
                      <div className="d-flex gap-2">
                        <Button type="primary" shape="round" onClick={this.toggleCustomizeCards}>+ Add Cards</Button>
                        <Popconfirm
                          placement="bottom"
                          title="Delete Report"
                          description="Are you sure about ?"
                          // open={open}
                          onConfirm={this.deleteReportCard}
                          showCancel={false}
                        >
                          <Button className="center-xy" type="default" shape="round" icon={<DeleteOutlined />} ></Button>
                        </Popconfirm>
                      </div>
                    </div>

                  </div>
                  <Col lg="12">
                    <Offcanvas
                      isOpen={this.state.cardsCanvasOpen}
                      direction="end"
                      toggle={this.toggleCustomizeCards}
                    >
                      <OffcanvasHeader toggle={this.toggleCustomizeCards}>
                        Cards
                      </OffcanvasHeader>
                      <OffcanvasBody className="p-0">
                        <SimpleBar>
                          {this.cardList().sort.map(card => (
                            <div key={card.key} className={classNames("card-container", card?.selected ? "checked" : "")} onClick={() => {
                              if (card?.selected) {
                                this.props.deleteCardToReport(
                                  this.state.openKeys,
                                  card,
                                  this.deleteCardToPatch(card),
                                  () => { }
                                )
                              } else {
                                this.props.addCardToReport(
                                  this.state.openKeys,
                                  card,
                                  this.addCardToPatch(card),
                                  () => { }
                                )
                              }
                            }}>
                              <div className="card-content">
                                {card.name}
                              </div>
                              <div className="card-icon">
                                <div className="card-icon-add">
                                  <i className="mdi mdi-plus" />
                                </div>
                                <div className="card-icon-check">
                                  <i className="mdi mdi-check" />
                                </div>
                                <div className="card-icon-delete">
                                  <i className="mdi mdi-delete" />
                                </div>
                              </div>
                            </div>
                          ))}
                        </SimpleBar>
                      </OffcanvasBody>
                    </Offcanvas>
                  </Col>
                  <Col lg="12">
                    <Row>
                      {this.props.currentReport?.cards?.map(i =>
                        <this.props.Report key={i.name} allows={{
                          [i.name]: true,
                        }} />
                      )}

                      {this.props.currentReport?.cards?.length == 0 && (
                        <div className="empty-card">
                          <div className="empty-card-img">
                            <img src="https://img8.zohostatic.com.au/social/v729/reach/images/add-widget.png" width="350px" />
                          </div>
                          <div className="empty-card-text">
                            Start adding Cards to build a Dashboard
                          </div>
                          <div className="d-flex gap-2">
                            <Button type="primary" shape="round" onClick={this.toggleCustomizeCards}>+ Add Card</Button>
                          </div>
                        </div>
                      )}
                    </Row>
                  </Col>
                </Row>
              ) : (
                <>
                  <div className="empty-card">
                    <div className="empty-card-img">
                      <img src="https://img8.zohostatic.com.au/social/v729/reach/images/add-widget.png" width="350px" />
                    </div>
                    <div className="empty-card-text">
                      Start adding New Dashboard
                    </div>
                    <div className="d-flex gap-2">
                      <Button type="primary" shape="round" onClick={(() => {
                        this.setState({
                          createDashboardName: ''
                        })
                        this.setState({
                          createDashboardModal: !this.state.createDashboardModal
                        })
                      }).bind(this)}>+ Create Dashboard</Button>
                    </div>
                  </div>
                </>
              )}
            </div>
            {/* Render Breadcrumb */}
          </div>
        </div>
      </React.Fragment >
    );
  }
}

CustomReport.propTypes = {
  t: PropTypes.any,
};

const mapStateToProps = ({ Location, CustomReport }) => {
  return ({
    locations: Location.locations,
    isLoading: Location.isLoading,
    selectedLocation: Location.selectedLocation,
    isLocationSelected: Location.isSelectedLocation,

    customReports: CustomReport.list,
    customReportsIsLoading: CustomReport.isLoading,
    customReportsIsLoadingSchedule: CustomReport.isLoadingSchedule,

    currentReport: CustomReport.current,

  })
};

const mapDispatchToProps = dispatch => ({
  selectLocation: (id, history) => dispatch(selectLocation(id, history)),
  selectCustomReport: (id) => dispatch(selectCustomReport(id)),
  fetchCustomReports: (id) => dispatch(fetchCustomReports(id)),
  updateReportSchedule: (card) => dispatch(updateReportSchedule(card)),
  createCustomReport: (card) => dispatch(createCustomReport(card)),
  addCardToReport: (pageIndex, card, patchData, cb) => {
    return dispatch(addCardToReport({ pageIndex, card, patchData, cb }))
  },
  deleteCardToReport: (pageIndex, card, patchData, cb) => {
    return dispatch(deleteCardToReport({ pageIndex, card, patchData, cb }))
  },
  deleteCustomReport: (id) => dispatch(deleteCustomReport(id)),
});

export default (withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(CustomReport))));
