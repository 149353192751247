/* TASKS */
export const LOCATION_LOADING_1 = 'LOCATION_LOADING_1'
export const LOCATION_LOADING_2 = 'LOCATION_LOADING_2'
export const GET_LOCATIONS = 'GET_LOCATIONS'
export const SELECT_LOCATION = 'SELECT_LOCATION'
export const GET_LOCATIONS_SUCCESS = 'GET_LOCATIONS_SUCCESS'
export const GET_LOCATIONS_FAIL = 'GET_LOCATIONS_FAIL'
export const ADD_LOCATION = 'ADD_LOCATION'
export const GET_SPECIFIC_LOCATION = 'GET_SPECIFIC_LOCATION'
export const UPDATE_LOCATION = 'UPDATE_LOCATION'
export const UPDATE_LOCATION_SUCCESS = 'UPDATE_LOCATION_SUCCESS'
export const DELETE_LOCATION = 'DELETE_LOCATION'
export const DELETE_LOCATION_SUCCESS = 'DELETE_LOCATION_SUCCESS'
export const LOCATION_TEST = 'LOCATION_TEST'
