import React, { Component, useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Container,
  Card,
  CardBody,
  Col,
  Row,
  CardHeader,
  Offcanvas,
  OffcanvasHeader,
  OffcanvasBody,
} from "reactstrap";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import SimpleBar from "simplebar-react";
import { withRouter } from "react-router";

//i18n
import { withTranslation } from "react-i18next";
import classNames from "classnames";

// Import FecthAPI
import { Button, Checkbox, Form, Input, Menu, Select, Switch, Tag, Tooltip, } from "antd";
import { MailOutlined, GoogleOutlined, StockOutlined, ExportOutlined, AppstoreOutlined, AppstoreFilled, FireOutlined, FilterOutlined } from '@ant-design/icons';
import { fetchBasicReports, selectBasicReport, updateReportScheduleBasic } from "../../../store/basicCard";
import { cardEnum } from "./CardConstant";

const TEST = [
  {
    "email": "fazilkazi7@gmail.com"
  },
  {
    "email": "kamran@kktechsolution.com"
  },
  {
    "email": "karan.saeimpex@gmail.com"
  }
]

const dateFormat = 'YYYY-MM-DD';

const tagRender = (props) => {
  const { label, value, closable, onClose } = props;
  const onPreventMouseDown = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };
  return (
    <>
      {/\S+@\S+\.\S+/.test(label) ? (
        <Tag

          onMouseDown={onPreventMouseDown}
          closable={closable}
          onClose={onClose}
          style={{
            marginRight: 3,
            display: "flex",
            alignItems: "center"
          }}
        >
          {label}
        </Tag>
      ) : (
        <>
          {onClose()}
        </>
      )}

    </>
  );
};

class ScheduleReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // schedulingCardIsOpen: true,
      formRef: React.createRef()
    };

    this.toggleCustomizeCards = this.toggleCustomizeCards.bind(this);
    this.formSubmitButton = this.formSubmitButton.bind(this);
    this.onFinish = this.onFinish.bind(this);
    // this.cardList = this.cardList.bind(this)
  }

  async componentDidMount() {
    console.log("=====================================================")
    if (!this.props.basicReports.length) {
    }

  }

  async componentDidUpdate(prevState, prevProps) {
    console.log("SCHEDULE REPORT", this.props.openKey)
    if (this.props.scheduleOpen) {
      this.props.selectBasicReport(this.props.openKey);
    }
  }


  async toggleCustomizeCards() {
    this.props.setSchedule(!this.props.scheduleOpen)
    // this.setState({ schedulingCardIsOpen: !this.state.schedulingCardIsOpen });
  }

  async onFinish(values) {
    const patchData = {
      name: this.props.currentReport.name,
      mail_body: values.mail_body || "",
      is_scheduled: values.is_scheduled ? 1 : 0,
      // card_ids: this.props.currentReport.cards.map(i => cardEnum(i.name)),
      emails: values.emails
    }

    const result = await this.props.updateReportScheduleBasic({
      id: this.props.currentReport.id,
      data: patchData
    })

    if(result.payload?.basic_report) {
      this.toggleCustomizeCards();
    }

    console.log("SUBMIT REQUEST: ", patchData, result);
  };

  formSubmitButton() {
    this.state.formRef.current.submit();
  }


  render() {

    return (
      <React.Fragment>
        <Offcanvas
          isOpen={this.props.scheduleOpen}
          direction="end"
          toggle={this.toggleCustomizeCards}
        >
          <OffcanvasHeader toggle={this.toggleCustomizeCards}>
            Schedule Report
          </OffcanvasHeader>
          <OffcanvasBody className="p-3">
            {this.props.currentReport?.id && (
              <Form
                ref={this.state.formRef}
                layout="vertical"
                initialValues={
                  {
                    name: this.props.currentReport.name,
                    mail_body: this.props.currentReport.mail_body,
                    is_scheduled: this.props.currentReport.is_scheduled,
                    // emails: this.props.currentReport.emails.map(i => i.email).join(","),
                    emails: this.props.currentReport.emails.map(i => i.email),
                  }
                }
                name="control-ref"
                onFinish={this.onFinish}
              // style={{ maxWidth: 600 }}
              >

                <div className="h6 mt-3">
                  Who should receive this report?
                </div>
                <Form.Item name="emails" rules={[{ required: true }]}>
                  {/* <div className="email-select"> */}
                  <Select
                    mode="tags"
                    className="email-select"
                    tagRender={tagRender}
                    aria-rowcount={4}
                    style={{ width: '100%' }}
                    filterOption={(inputValue, option) => {
                      return option.value.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0 &&
                        /\S+@\S+\.\S+/.test(inputValue)
                    }
                    }
                    tokenSeparators={[',']}
                  >
                  </Select>
                </Form.Item>

                <div className="mt-3"></div>
                <div className="h6">
                  Send a message with your report?
                </div>
                <Form.Item name="mail_body">
                  <Input.TextArea rows={4} />
                </Form.Item>

                <div className="h6">
                  Schdeule report?
                </div>
                <Form.Item name="is_scheduled" valuePropName="checked">
                  <Switch />
                </Form.Item>
              </Form>
            )}

          </OffcanvasBody>

          {this.props.currentReport?.id && (
            <div className="schedule-footer">
              <Button loading={this.props.basicReportsIsLoadingSchedule} type="primary" onClick={this.formSubmitButton} >Save</Button>
            </div>
          )}

        </Offcanvas>
      </React.Fragment >
    );
  }
}

ScheduleReport.propTypes = {
  t: PropTypes.any,
};

const mapStateToProps = ({ Location, BasicReport }) => {
  return ({
    locations: Location.locations,
    isLoading: Location.isLoading,
    selectedLocation: Location.selectedLocation,
    isLocationSelected: Location.isSelectedLocation,

    basicReports: BasicReport.list,
    basicReportsIsLoading: BasicReport.isLoading,
    basicReportsIsLoadingSchedule: BasicReport.isLoadingSchedule,

    currentReport: BasicReport.current,

  })
};

const mapDispatchToProps = dispatch => ({
  fetchBasicReports: (id) => dispatch(fetchBasicReports(id)),
  selectBasicReport: (id) => dispatch(selectBasicReport(id)),
  updateReportScheduleBasic: (card) => dispatch(updateReportScheduleBasic(card)),
});

export default (withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(ScheduleReport))));
