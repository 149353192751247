/* TASKS */
export const BANNER_LOADING_1 = 'BANNER_LOADING_1'
export const BANNER_LOADING_2 = 'BANNER_LOADING_2'
export const GET_BANNERS = 'GET_BANNERS'
export const GET_BANNERS_SUCCESS = 'GET_BANNERS_SUCCESS'
export const GET_BANNERS_FAIL = 'GET_BANNERS_FAIL'
export const ADD_BANNER = 'ADD_BANNER'
export const GET_SPECIFIC_BANNER = 'GET_SPECIFIC_BANNER'
export const UPDATE_BANNER = 'UPDATE_BANNER'
export const UPDATE_BANNER_SUCCESS = 'UPDATE_BANNER_SUCCESS'
export const DELETE_BANNER = 'DELETE_BANNER'
export const DELETE_BANNER_SUCCESS = 'DELETE_BANNER_SUCCESS'
export const BANNER_TEST = 'BANNER_TEST'
