import { message } from "antd";
import { GET_BANNERS_SUCCESS, GET_BANNERS_FAIL, GET_BANNERS, UPDATE_BANNER_SUCCESS, DELETE_BANNER_SUCCESS, BANNER_LOADING_1, BANNER_LOADING_2 } from "./actionTypes"

const INIT_STATE = {
  list: [
  ],
  single: {},
  pagination: {
    pageSize: 5,
    current: 0,
    total: 0,
  },
  isLoading: false,
  isUpdateLoading: false,
  loading1: false,
  loading2: false,
  sort: {},
  error: {},
}

const banners = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_BANNERS_SUCCESS:
      const data = action.payload;
      return {
        ...state,
        isLoading: false,
        list: data?.data,
        error: {},
        pagination: {
          pageSize: data?.per_page,
          current: data?.current_page,
          total: data?.total,
        }
      }

    case GET_BANNERS_FAIL:
      message.error(action.payload.message || 'Something went wrong')
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      }

    case UPDATE_BANNER_SUCCESS:
      return {
        ...state,
        single: action.payload,
      }

    case DELETE_BANNER_SUCCESS:
      console.log("DELETE BANNER")
      return {
        ...state,
        list: [...state.list.filter(i => i.id != action.payload.id)]
      }

    case BANNER_LOADING_1:
      return {
        ...state,
        loading1: action.payload
      }

    case BANNER_LOADING_2:
      return {
        ...state,
        loading2: action.payload
      }


    case GET_BANNERS:
      return {
        ...state,
        error: {},
        isLoading: true
      }


    default:
      return state
  }
}

export default banners
