import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import { ADD_BANNER, BANNER_TEST, DELETE_BANNER, GET_BANNERS, GET_SPECIFIC_BANNER, UPDATE_BANNER } from "./actionTypes"
import { getBannerSuccess, getBannerFail, updateBannerLoading1, updateBannerSuccess, deleteBannerSuccess } from "./actions"
import service from "../../helpers/Api/FetchInterceptor"

//Include Both Helper File with needed methods
function getBanners(params) {
  return async function () {
    let result = await service.get('/banners' + "?" + params);
    return result.data;
  }
}
function getBanner(id) {
  return async function () {
    let result = await service.get('/banners/' + id);
    return result.data;
  }
}

function putBanner(banner) {
  return async function () {
    const formData = new FormData();
    Object.keys(banner).forEach(key => formData.append(key, object[key]));
    let result = await service.put('/banners', {
      data: formData
    });
    return result.data;
  }
}

function* fetchBanner({
  payload: params
}) {
  try {
    const response = yield call(getBanners(params))
    yield put(getBannerSuccess(response))
  } catch (error) {
    yield put(getBannerFail(error))
  }
}

function* addBanner({
  payload
}) {
  try {
    yield put(updateBannerLoading1(true))
    const response = yield call(() => payload)
    yield put(updateBannerSuccess(response.data.banner))
  } catch (error) {
    yield put(getBannerFail(error))
  } finally {
    yield put(updateBannerLoading1(false))
  }
}

function* updateBanner({
  payload
}) {
  try {
    yield put(updateBannerLoading1(true))
    const response = yield call(() => payload)
    yield put(updateBannerSuccess(response.data.banner))
  } catch (error) {
    yield put(getBannerFail(error))
  } finally {
    yield put(updateBannerLoading1(false))
  }
}

function* fetchBannerWithId({
  payload
}) {
  try {
    yield put(updateBannerLoading1(true))
    const response = yield call(() => payload)
    yield put(updateBannerSuccess(response.data.banner))
  } catch (error) {
    yield put(getBannerFail(error))
  } finally {
    yield put(updateBannerLoading1(false))
  }
}

function* deleteBanner({
  payload
}) {
  try {
    yield put(updateBannerLoading1(true))
    const response = yield call(() => payload)
    console.log('called', response)
    yield put(deleteBannerSuccess(response.data.banner))
  } catch (error) {
    yield put(getBannerFail(error))
  } finally {
    yield put(updateBannerLoading1(false))
  }
}

function* fetchTest({
  payload
}) {
  try {
    yield put(updateBannerLoading1(true))
    const response = yield call(() => payload)
  } catch (error) {
    yield put(getBannerFail(error))
  } finally {
    yield put(updateBannerLoading1(false))
  }
}

function* bannersSaga() {
  yield takeEvery(GET_BANNERS, fetchBanner)
  yield takeEvery(ADD_BANNER, addBanner)
  yield takeEvery(UPDATE_BANNER, updateBanner)
  yield takeEvery(GET_SPECIFIC_BANNER, fetchBannerWithId)
  yield takeEvery(DELETE_BANNER, deleteBanner)
  yield takeEvery(BANNER_TEST, fetchTest)
}

export default bannersSaga
