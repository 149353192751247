import React, { Component, useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Container,
  Card,
  CardBody,
  Col,
  Row,
} from "reactstrap";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { withRouter } from "react-router";

//i18n
import { withTranslation } from "react-i18next";

import classNames from "classnames";

// import images
import img1 from "../../assets/images/small/img-2.jpg"
import avtar1 from "../../assets/images/users/avatar-2.jpg"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

// Import FecthAPI
import { selectLocation } from "../../store/actions";
import moment from "moment";
import RatingTooltip from "react-rating-tooltip"
import { fetchLocationReview, reviewReply } from "../../helpers/backend_helper";
import PaginationComponet from "./PaginationComponet";


import { CommentOutlined } from '@ant-design/icons';
import { Empty, Input, message, Modal } from "antd";


const MenuComponent = (props) => {
  return (
    <>
      <div className="cst-menu-body">
        <div className={`cst-menu-body-item ${true ? 'active' : ''}`}>
          <CommentOutlined /> Reviews
        </div>
      </div>

    </>
  );
};

class Reviews extends PaginationComponet {
  constructor(props) {
    super(props);
    console.log(this.state, "FIRST ----------------<>");
    this.state = {
      reviewText: "",
      openReview: false,
      reviewId: "",
      locationId: "",
      isLoadingReviewUpdate: false,
      ...this.state,
    };
    this.dataKey = "reviews"
    this.fetchAPI = fetchLocationReview

    this.handleOkUpdateReview = this.handleOkUpdateReview.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.clickOnReply = this.clickOnReply.bind(this);

  }

  async componentDidMount() {
    console.log("===============MOUNT===============")
  }

  ratingConvert(str) {
    const MAP = {
      "ONE": 1,
      "TWO": 3,
      "THREE": 3,
      "FOUR": 4,
      "FIVE": 5,
    }
    return MAP[str] || 0;
  }


  componentWillUnmount() {
  }

  handleOkUpdateReview() {
    console.log("XALLED")
    this.setState({
      isLoadingReviewUpdate: true,
    })

    const that = this;
    reviewReply({
      review_id: this.state.reviewId,
      location_id: this.state.locationId,
      comment: this.state.reviewText,
    }).then(res => {
      this.setState({
        items: this.state.items.map(i => {
          if (i.reviewId == that.state.reviewId) {
            return {
              ...i,
              reviewReply: { comment: that.state.reviewText },
            }
          } else {
            return i
          }
        })
      })
      message.success("Review updated");
    }).finally(() => {
      that.handleCancel();
      that.setState({
        reviewId: "",
        locationId: "",
        reviewText: "",
      })
    })
    // that.handleCancel();
  }

  handleCancel() {
    this.setState({
      openReview: false,
      isLoadingReviewUpdate: false,
    })
  }

  clickOnReply({
    reviewId,
    locationId,
    reviewText = ""
  }) {
    this.setState({
      openReview: true,
      reviewText: reviewText,
      isLoadingReviewUpdate: false,
      reviewId,
      locationId
    })
  }
  render() {
    //meta title
    document.title = "Reviews";

    return (
      <React.Fragment >
        <Modal
          title="Reply to Review"
          open={this.state.openReview}
          onOk={this.handleOkUpdateReview}
          okText="Reply"
          confirmLoading={this.state.isLoadingReviewUpdate}
          onCancel={this.handleCancel}
        >
          <Input.TextArea rows={4} value={this.state.reviewText} onChange={(e => {
            this.setState({
              reviewText: e.target.value
            })
          }).bind(this)}></Input.TextArea>
        </Modal>
        <div className="page-content">
          <Container fluid>

            <div className="row">
              <div className="col-2 sub-navigation post-page">
                <div className="sub-navigation-container">
                  <div className="cst-menu">
                    <div className="cst-menu-header">
                      <div className="cst-menu-header-text">GMB Reviews</div>
                    </div>
                  </div>
                  <MenuComponent openPage={this.state.openPage} setOpenPage={this.setOpenPage} />
                </div>
              </div>
              <div className="col-2"></div>
              <div className="col-10">
                <div className="mt-4">
                  <Breadcrumbs
                    title={this.props.t("Dashboards")}
                    breadcrumbItem={this.props.t("Reviews")}
                  />
                  <Card>
                    <CardBody>
                      {/*Put the scroll bar always on the bottom*/}
                      <this.InfiniteScroll>
                        {!this.state.items.length && this.state.lastPage && (
                          <div className="d-flex justify-content-center w-100">
                            <Empty />
                          </div>
                        )}
                        {this.state.items.map((rev) => (
                          <div key={rev.reviewId || Math.random()}>
                            <div>
                              <div className="media py-3 border-top">
                                <div className="avatar-xs me-3">
                                  <div className="avatar-title rounded-circle bg-light text-primary">
                                    <img src={rev.reviewer?.profilePhotoUrl} alt="" style={{
                                      width: "100%",
                                      height: "100%"
                                    }} />
                                  </div>
                                </div>
                                <div className="media-body">
                                  <h5 className="font-size-14 mb-1">
                                    {rev?.reviewer?.displayName}{" "}
                                    <small className="text-muted float-end">
                                      {moment(rev.updateTime).fromNow()}
                                    </small>
                                  </h5>
                                  <div className="rating-component">
                                    <RatingTooltip
                                      max={5}
                                      style={{ justifyContent: 'left' }}
                                      disabled={true}
                                      ActiveComponent={
                                        <i
                                          className="mdi mdi-star text-primary"
                                          style={{ fontSize: "15px" }}
                                        />
                                      }
                                      InActiveComponent={
                                        <i
                                          className="mdi mdi-star-outline text-muted"
                                          style={{ fontSize: "15px" }}
                                        />
                                      }
                                      defaultRating={this.ratingConvert(rev.starRating)}
                                    />
                                  </div>
                                  {rev.comment && (

                                    <p className="text-muted">
                                      {rev.comment}
                                    </p>
                                  )}
                                  {!rev.reviewReply && (
                                    <div>
                                      <Link to="#" className="text-success" onClick={() => this.clickOnReply({
                                        reviewId: rev.reviewId,
                                        locationId: this.props.selectedLocation.id
                                      })}>
                                        <i className="mdi mdi-reply"></i> Reply
                                      </Link>
                                    </div>
                                  )}
                                  {rev.reviewReply && (
                                    <div className="media pt-3">
                                      <div className="avatar-xs me-3">
                                        <div className="avatar-title rounded-circle bg-light text-primary">
                                          <i className="bx bxs-user"></i>
                                        </div>
                                      </div>
                                      <div className="media-body pt-1">
                                        <p className="text-muted">
                                          {rev.reviewReply.comment}
                                        </p>
                                        <div>
                                          <Link to="#" className="text-success" onClick={() => this.clickOnReply({
                                            reviewText: rev.reviewReply.comment,
                                            reviewId: rev.reviewId,
                                            locationId: this.props.selectedLocation.id
                                          })}>
                                            <i className="zso-edit-line cursor-pointer"></i>{" "}
                                            Edit
                                          </Link>
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                      </this.InfiniteScroll>

                      {/* <div className="">
                      <h5 className="font-size-15">
                        <i className="bx bx-message-dots text-muted align-middle me-1"></i>{" "}
                        Comments :
                      </h5>

                      <div>
                        <div className="media py-3">
                          <div className="avatar-xs me-3">
                            <div className="avatar-title rounded-circle bg-light text-primary">
                              <i className="bx bxs-user"></i>
                            </div>
                          </div>
                          <div className="media-body">
                            <h5 className="font-size-14 mb-1">
                              Delores Williams{" "}
                              <small className="text-muted float-end">
                                1 hr Ago
                              </small>
                            </h5>
                            <p className="text-muted">
                              If several languages coalesce, the grammar
                              of the resulting language is more simple and
                              regular than that of the individual
                            </p>
                            <div>
                              <Link to="#" className="text-success">
                                <i className="mdi mdi-reply"></i> Reply
                              </Link>
                            </div>
                          </div>
                        </div>
                        <div className="media py-3 border-top">
                          <div className="avatar-xs me-3">
                            <img
                              src={avtar1}
                              alt=""
                              className="img-fluid d-block rounded-circle"
                            />
                          </div>
                          <div className="media-body">
                            <h5 className="font-size-14 mb-1">
                              Clarence Smith{" "}
                              <small className="text-muted float-end">
                                2 hrs Ago
                              </small>
                            </h5>
                            <p className="text-muted">
                              Neque porro quisquam est, qui dolorem ipsum
                              quia dolor sit amet
                            </p>
                            <div>
                              <Link to="#" className="text-success">
                                <i className="mdi mdi-reply"></i> Reply
                              </Link>
                            </div>

                            <div className="media pt-3">
                              <div className="avatar-xs me-3">
                                <div className="avatar-title rounded-circle bg-light text-primary">
                                  <i className="bx bxs-user"></i>
                                </div>
                              </div>
                              <div className="media-body">
                                <h5 className="font-size-14 mb-1">
                                  Silvia Martinez{" "}
                                  <small className="text-muted float-end">
                                    2 hrs Ago
                                  </small>
                                </h5>
                                <p className="text-muted">
                                  To take a trivial example, which of us
                                  ever undertakes laborious physical
                                  exercise
                                </p>
                                <div>
                                  <Link to="#" className="text-success">
                                    <i className="mdi mdi-reply"></i>{" "}
                                    Reply
                                  </Link>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="media py-3 border-top">
                          <div className="avatar-xs me-3">
                            <div className="avatar-title rounded-circle bg-light text-primary">
                              <i className="bx bxs-user"></i>
                            </div>
                          </div>
                          <div className="media-body">
                            <h5 className="font-size-14 mb-1">
                              Keith McCoy{" "}
                              <small className="text-muted float-end">
                                12 Aug
                              </small>
                            </h5>
                            <p className="text-muted">
                              Donec posuere vulputate arcu. phasellus
                              accumsan cursus velit
                            </p>
                            <div>
                              <Link to="#" className="text-success">
                                <i className="mdi mdi-reply"></i> Reply
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div> */}
                    </CardBody>
                  </Card>
                </div>
              </div>
            </div>
            {/* Render Breadcrumb */}
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

Reviews.propTypes = {
  t: PropTypes.any,
};

const mapStateToProps = ({ Location }) => {
  return ({
    locations: Location.locations,
    isLoading: Location.isLoading,
    selectedLocation: Location.selectedLocation,
    isLocationSelected: Location.isSelectedLocation
  })
};

const mapDispatchToProps = dispatch => ({
  selectLocation: (id, history) => dispatch(selectLocation(id, history)),
});

export default (withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(Reviews))));
