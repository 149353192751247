import { createSlice, createAsyncThunk, current } from '@reduxjs/toolkit';
import { message } from 'antd';
import { history } from '../../App';
import service from '../../helpers/Api/FetchInterceptor';

const API_URL = "/custom_reports"

// Define a thunk function for fetching data
export const fetchCustomReports = createAsyncThunk(
  'customReport/fetchCustomReports',
  async (id) => {
    let result = await service.get(`${API_URL}?id=${id}&allPage=true&sort[field]=created_at&sort[order]=DESC`);
    return result.data;
  }
);

// Define a thunk function for creating data
export const fetchSpecificCustomReports = createAsyncThunk(
  'customReport/fetchSpecificCustomReports',
  async (id) => {
    const result = await service.get(API_URL + "/" + id);
    return result.data;
  }
);
// Define a thunk function for creating data
export const createCustomReport = createAsyncThunk(
  'customReport/createCustomReport',
  async (card) => {
    let result = await service.post(`/custom_reports`, {
      ...card.data
    });
    return result.data;
  }
);

// Define a thunk function for updating data
export const updateCustomReport = createAsyncThunk(
  'customReport/updateCustomReport',
  async (updatedCustomReport) => {
    const [id, values] = updatedCustomReport;
    console.log("UPDATE", updatedCustomReport)
    const formData = new FormData();
    Object.keys(values).forEach(key => formData.append(key, values[key]));
    let result = await service({
      url: API_URL + "/" + id,
      method: "POST",
      data: formData
    });
    return result.data;
  }
);

/* export const addCardToReport = createAsyncThunk(
  'customReport/addCardToReport',
  async (card) => {
    console.log("CARD: ", card, card?.card)
    const pageIndex = card?.pageIndex;

    // console.log("XXXXXXXXXXXXXXXXXXXX");
    let data = await new Promise((resolve) => {
      return resolve({
        pageIndex: pageIndex,
        card: card.card,
        cb: card.cb
      })
    })
    return data;
  }
);
 */
export const addCardToReport = createAsyncThunk(
  'customReport/addCardToReport',
  async (card) => {
    console.log("CARD: ", card, card?.card)
    const pageIndex = card?.pageIndex;

    const result = await service.patch(`/custom_reports/${card?.patchData.id}`, {
      ...card?.patchData
    });

    return ({
      result: result.data,
      pageIndex: pageIndex,
      card: card.card,
      cb: card.cb
    })
  }
);


export const updateReportSchedule = createAsyncThunk(
  'customReport/updateReportSchedule',
  async (card) => {
    let result = await service.patch(`/custom_reports/${card.id}`, {
      ...card.data
    });
    return result.data;
  }
)

export const deleteCardToReport = createAsyncThunk(
  'customReport/deleteCardToReport`',
  async (card) => {
    console.log("CARD: ", card, card?.card)
    const pageIndex = card?.pageIndex;

    const result = await service.patch(`/custom_reports/${card?.patchData.id}`, {
      ...card?.patchData
    });

    return ({
      result: result.data,
      pageIndex: pageIndex,
      card: card.card,
      cb: card.cb
    })
  }
);

// Define a thunk function for deleting data
export const deleteCustomReport = createAsyncThunk(
  'customReport/deleteCustomReport',
  async (id) => {
    await service.delete(`/custom_reports/${id}`);
    return id;
  }
);

// Define a slice with initial state and reducers
const customReportSlice = createSlice({
  name: 'customReport',
  initialState: {
    single: {},
    current: {},
    list: [],
    pagination: {
      pageSize: 5,
      current: 0,
      total: 0,
    },
    isLoading: false,
    isLoadingSchedule: false,
    isLoadingCardUpdate: false,
    isDeleteLoading: false,
    isCreateLoading: false,
    sort: {},
    error: null,
  },
  reducers: {
    selectCustomReport(state, action) {
      const index = state.list.findIndex(i => i.id == action.payload)
      if (index == -1) {
        state.error = "Selected Report not found";
        return;
      }
      state.current = state.list[index];
    },
  },
  extraReducers: (builder) => {
    // Add reducers for fetching data
    builder
      .addCase(fetchCustomReports.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchCustomReports.fulfilled, (state, action) => {
        state.isLoading = false;
        const data = action.payload
        state.list = data;
      })
      .addCase(fetchCustomReports.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      });

    // Add reducers for fetching data
    builder
      .addCase(fetchSpecificCustomReports.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchSpecificCustomReports.fulfilled, (state, action) => {
        state.isLoading = false;
        const data = action.payload
        state.single = data?.exibitor
      })
      .addCase(fetchSpecificCustomReports.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      });

    // Add reducers for creating data
    builder
      .addCase(createCustomReport.pending, (state) => {
        state.isCreateLoading = true;
        state.error = null;
      })
      .addCase(createCustomReport.fulfilled, (state, action) => {
        state.isCreateLoading = false;
        state.list = [action.payload.custom_report, ...state.list];
        state.current = action.payload.custom_report
      })
      .addCase(createCustomReport.rejected, (state, action) => {
        state.isCreateLoading = false;
        state.error = action.error.message;
      });

    builder
      .addCase(addCardToReport.pending, (state) => {
        state.isLoadingCardUpdate = true;
        state.error = null;
      })
      .addCase(addCardToReport.fulfilled, (state, action) => {
        state.isLoadingCardUpdate = false;
        console.log("ACTION:", action)
        state.list[action.payload.pageIndex].cards = [...state.list[action.payload.pageIndex].cards, { name: action.payload.card.key }]
        action.payload?.cb();
      })
      .addCase(addCardToReport.rejected, (state, action) => {
        state.isLoadingCardUpdate = false;
        state.error = action.error.message;
      });

    builder
      .addCase(deleteCardToReport.pending, (state) => {
        state.isLoadingCardUpdate = true;
        state.error = null;
      })
      .addCase(deleteCardToReport.fulfilled, (state, action) => {
        state.isLoadingCardUpdate = false;
        console.log("ACTION:", action)
        state.list[action.payload.pageIndex].cards = state.list[action.payload.pageIndex].cards.filter(i => i.name != action.payload.card.key)
        action.payload?.cb();
      })
      .addCase(deleteCardToReport.rejected, (state, action) => {
        state.isLoadingCardUpdate = false;
        state.error = action.error.message;
      });

    // Add reducers for updating data
    builder
      .addCase(updateCustomReport.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(updateCustomReport.fulfilled, (state, action) => {
        state.isLoading = false;
        const index = state.list.findIndex(exibitor => exibitor.id === action.payload.id);
        if (index !== -1) {
          state.list[index] = action.payload;
        }
        history.push("/customReport")
      })
      .addCase(updateCustomReport.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      });

    builder
      .addCase(updateReportSchedule.pending, (state) => {
        state.isLoadingSchedule = true;
        state.error = null;
      })
      .addCase(updateReportSchedule.fulfilled, (state, action) => {
        state.isLoadingSchedule = false;
        const index = state.list.findIndex(exibitor => exibitor.id === action.payload.custom_report.id);
        if (index !== -1) {
          state.list[index] = action.payload.custom_report;
          state.current = action.payload.custom_report
        }
      })
      .addCase(updateReportSchedule.rejected, (state, action) => {
        state.isLoadingSchedule = false;
        state.error = action.error.message;
      });

    builder
      .addCase(deleteCustomReport.pending, (state) => {
        state.isDeleteLoading = true;
        state.error = null;
      })
      .addCase(deleteCustomReport.fulfilled, (state, action) => {
        state.isDeleteLoading = false;
        state.list = state.list.filter(exibitor => exibitor.id !== action.payload);
        state.current = {};
      })
      .addCase(deleteCustomReport.rejected, (state, action) => {
        state.isDeleteLoading = false;
        state.error = action.error.message;
      });
  }
})

// Export the action creators and the reducer
export const { selectCustomReport } = customReportSlice.actions;
export default customReportSlice.reducer;
