import React, { useEffect, useState } from "react"
import PropTypes from 'prop-types'
import { Route, Redirect, useHistory, useParams } from "react-router-dom"
import GoogleLoginModal from "../components/Common/GoogleLoginModal"
import BasicWrapperToHandleLocations from "../components/Common/BasicWrapperToHandleLocations"
import { useDispatch } from "react-redux"
import { selectLocation } from "../store/actions"

function Render(props) {

  const [key, setKey] = useState(Math.random());

  const { id: ID } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    if (typeof ID != "undefined") {
      rerender();
      dispatch(selectLocation(ID, history))
    }
  }, [ID])

  function rerender() {
    setKey(Math.random())
  }

  return (<>
    <props.Component {...props} rerender={rerender} key={key} />
  </>)
}

const AppRoute = ({
  component: Component,
  layout: Layout,
  isAuthProtected,
  ...rest
}) => (
  <Route
    {...rest}
    render={props => {
      if (isAuthProtected && !localStorage.getItem("authUser")) {
        return (
          <Redirect
            to={{ pathname: "/login", state: { from: props.location } }}
          />
        )
      }

      return (
        <Layout>
          {isAuthProtected ? (
            <GoogleLoginModal>
              <BasicWrapperToHandleLocations>
                <Render
                  Component={Component}
                  {...props}
                />
              </BasicWrapperToHandleLocations>
            </GoogleLoginModal>
          ) : (
            <Component {...props} />
          )}
        </Layout>
      )
    }}
  />
)

AppRoute.propTypes = {
  isAuthProtected: PropTypes.bool,
  component: PropTypes.any,
  location: PropTypes.object,
  layout: PropTypes.any
}

export default AppRoute
