import React, { Component, useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Container,
  Card,
  CardBody,
  Col,
  Row,
  CardHeader,
  Badge,
} from "reactstrap";
import { Route, Redirect, useHistory, useParams } from "react-router-dom"
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { Switch, withRouter } from "react-router";


//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

//i18n
import { withTranslation } from "react-i18next";
import classNames from "classnames";

// Import FecthAPI
import { Avatar, Button, DatePicker, Menu, Tooltip, } from "antd";
import ReactApexChart from "react-apexcharts";
import RatingTooltip from "react-rating-tooltip";
import { StockOutlined, FireOutlined, FilterOutlined } from '@ant-design/icons';
import { getDashboardInsides, getDashboardInsidesDateWise, getUnAuthDashboardInsidesDateWise } from "../../helpers/backend_helper";
import moment from "moment";
import dayjs from 'dayjs';
import html2canvas from 'html2canvas';
import ColumnChart from "../Locations/Component/Chart";
import { fetchBasicReports } from "../../store/actions";

function getItem(label, key, icon, children, type) {
  return {
    key,
    icon,
    children,
    label,
    type,
  };
}

const items = [
  getItem('Summary', 'sub1', <FilterOutlined />),
  getItem('Questions & Reviews', 'sub2', <FireOutlined />),
  getItem('Performance', 'sub3', <StockOutlined />),
];

// submenu keys of first level
const rootSubmenuKeys = ['sub1', 'sub2', 'sub3'];

const MenuComponent = (props) => {
  const setOpenKeys = props.setOpenKeys
  const openKeys = props.openKeys
  let historyX = useHistory();
  const onOpenChange = (keys) => {
    console.log(keys)
    const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
    if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      setOpenKeys(keys);
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    }
  };
  return (
    <>
      <div className="cst-menu-body">
        <div className={`cst-menu-body-item ${props.openKeys == SUMMARY ? 'active' : ''}`} onClick={function () {
          // historyX.push(`/${props.LocationID}/custom-report/${i.id}`)
          setOpenKeys(SUMMARY)
        }}>
          <FilterOutlined /> Summary
        </div>
        <div className={`cst-menu-body-item ${props.openKeys == QA ? 'active' : ''}`} onClick={function () {
          // historyX.push(`/${props.LocationID}/custom-report/${i.id}`)
          setOpenKeys(QA)
        }}>
          <FireOutlined /> Questions & Reviews
        </div>
        <div className={`cst-menu-body-item ${props.openKeys == PERFORMANCE ? 'active' : ''}`} onClick={function () {
          // historyX.push(`/${props.LocationID}/custom-report/${i.id}`)
          setOpenKeys(PERFORMANCE)
        }}>
          <StockOutlined /> Performance
        </div>
      </div>

    </>
  );
};

const SUMMARY = "sub1";
const QA = "sub2";
const PERFORMANCE = "sub3"
const CUSTOM_REPORT = "sub4"

const REPORT = {
  SEARCH: "SEARCH",
  MAP: "MAP",
  VISITOR: "VISITOR",
  CONVERSATION: "CONVERSATION",
  R_Q_SUMMARY: "R_Q_SUMMARY",
  PERFORMANCE_SUMMARY: "PERFORMANCE_SUMMARY",
  REVIEW_GROWTH: "REVIEW_GROWTH",
  QUESTION_GROWTH: "QUESTION_GROWTH",
  ALL_SUMARY: "ALL_SUMARY",
}

const dateFormat = 'YYYY-MM-DD';

// const DEFAULT_RANGE = [
//   dayjs().add(-30, 'd'),
//   dayjs()
// ]
const DEFAULT_RANGE = [
  dayjs().subtract(1, 'month').startOf('month'),
  dayjs().subtract(1, 'month').endOf('month')
]

const rangePresets = [
  {
    label: 'Today',
    value: [dayjs(), dayjs()],
  },
  {
    label: 'Last 7 Days',
    value: [dayjs().add(-7, 'd'), dayjs()],
  },
  {
    label: 'Last 15 Days',
    value: [dayjs().add(-15, 'd'), dayjs()],
  },
  {
    label: 'Last 30 Days',
    value: [dayjs().add(-30, 'd'), dayjs()],
  },
  {
    label: 'Last Week',
    value: [dayjs().subtract(1, 'week').startOf('week'), dayjs().subtract(1, 'week').endOf('week')],
  },
  {
    label: 'Last Month',
    value: [dayjs().subtract(1, 'month').startOf('month'), dayjs().subtract(1, 'month').endOf('month')],
  },
];

class PdfReports extends Component {
  constructor(props) {
    super(props);
    const { match } = this.props;
    this.state = {
      divRef: React.createRef(),
      linkRef: React.createRef(),
      scheduleOpen: false,
      openKeys: SUMMARY,
      locationId: 0,
      customQuery: {},
      dashboard: {},
      dates: match.params?.startDate ? [dayjs(match.params?.startDate, "YYYY-MM-DD"), dayjs(match.params?.endDate, "YYYY-MM-DD")] : DEFAULT_RANGE,
      value: null,
      summary: {
        reviews: [],
        reviewCount: 0,
        averageRating: 0,
        questions: [],
        searchImpression: {
          value: 0,
          percentage: 0
        },
        mapImpression: {
          value: 0,
          percentage: 0
        },
        conversations: {
          value: 0,
          percentage: 0
        },
        websiteVisites: 0,
        directions: 0,
        phone: 0,
        searchReport: [],
        mapReport: [],
        websiteClickReport: [],
        directionReport: [],
        callClickReport: [],
        conversationsReport: [],
      },
      cardInfo: {},
      cards: [],
      location: {},
      reports: [
      ],
    };
    this.Report = this.Report.bind(this);
    this.setSchedule = this.setSchedule.bind(this);
  }

  async componentDidMount() {
    console.log("===============MOUNT===============")
    this.updateReports();

  }

  async updateReports() {
    const { match } = this.props;
    let ID = match.params.id;
    let LocationID = match.params.locationId;
    if (ID == 0) return;

    const START_DATE = dayjs(this.state.dates[0]).format(dateFormat)
    const END_DATE = dayjs(this.state.dates[1]).format(dateFormat)

    const data = await getUnAuthDashboardInsidesDateWise(LocationID, ID, START_DATE, END_DATE);
    const cardObj = {};
    const cardInfo = data["Details"]["card"];
    const cards = data["Details"]["card"]["cards"];
    const location = data["Details"]["location"];
    cards.map(i => {
      cardObj[i.name] = true;
    })
    document.title = "location?.title" || document.title


    this.setState({
      reports: [
        { title: 'Desktop Maps', iconClass: "zso-desktop", description: data['BUSINESS_IMPRESSIONS_DESKTOP_MAPS']['c_business_impression_desktop_maps'], percentage: data['BUSINESS_IMPRESSIONS_DESKTOP_MAPS']['per_business_impression_desktop_maps'] },
        { title: 'Desktop Search', iconClass: "zso-search", description: data['BUSINESS_IMPRESSIONS_DESKTOP_SEARCH']['c_business_impression_desktop_search'], percentage: data['BUSINESS_IMPRESSIONS_DESKTOP_SEARCH']['per_business_impression_desktop_search'] },
        { title: 'Mobile Maps', iconClass: "zso-mobile", description: data['BUSINESS_IMPRESSIONS_MOBILE_MAPS']['c_business_impression_mobile_maps'], percentage: data['BUSINESS_IMPRESSIONS_MOBILE_MAPS']['per_business_impression_mobile_maps'] },
        { title: 'Mobile Search', iconClass: "zso-search", description: data['BUSINESS_IMPRESSIONS_MOBILE_SEARCH']['c_business_impression_mobile_search'], percentage: data['BUSINESS_IMPRESSIONS_MOBILE_SEARCH']['per_business_impression_mobile_search'] },
        { title: 'Conversations', iconClass: "bx bx-copy-alt", description: data['BUSINESS_CONVERSATIONS']['c_business_conversations'], percentage: data['BUSINESS_CONVERSATIONS']['per_business_conversations'] },
        { title: 'Direct Request', iconClass: "zso-user", description: data['BUSINESS_DIRECTION_REQUESTS']['c_business_direction_requests'], percentage: data['BUSINESS_DIRECTION_REQUESTS']['per_business_direction_requests'] },
        { title: 'Call Clicks', iconClass: "zso-phone", description: data['CALL_CLICKS']['c_call_clicks'], percentage: data['CALL_CLICKS']['per_call_clicks'] },
        { title: 'Website Clicks', iconClass: "zso-flag", description: data['WEBSITE_CLICKS']['c_webiste_clicks'], percentage: data['WEBSITE_CLICKS']['per_webiste_clicks'] },
        { title: 'Business Booking', iconClass: "zso-GMB", description: data['BUSINESS_BOOKINGS']['c_business_bookings'], percentage: data['BUSINESS_BOOKINGS']['per_business_bookings'] },
        // { title: 'Food Orders', iconClass: "bx bx-copy-alt", description: data['BUSINESS_FOOD_ORDERS']['c_business_food_orders'], percentage: data['BUSINESS_FOOD_ORDERS']['per_business_food_orders'] },
      ],

      location: location,
      cards: cardObj,
      cardInfo: cardInfo,

      summary: {
        reviews: data["RAW_DATA"]?.["reviews"] || [],
        reviewCount: data["RAW_DATA"]?.["total_review_count"] || 0,
        averageRating: data["RAW_DATA"]?.["average_rating"] || 0,
        questions: data["RAW_DATA"]?.["questions"] || [],
        searchImpression: {
          value: data['BUSINESS_IMPRESSIONS_DESKTOP_SEARCH']['c_business_impression_desktop_search'],
          percentage: data['BUSINESS_IMPRESSIONS_DESKTOP_SEARCH']['per_business_impression_desktop_search']
        },
        mapImpression: {
          value: data['BUSINESS_IMPRESSIONS_DESKTOP_MAPS']['c_business_impression_desktop_maps'],
          percentage: data['BUSINESS_IMPRESSIONS_DESKTOP_MAPS']['per_business_impression_desktop_maps']
        },
        conversations: {
          value: data['BUSINESS_CONVERSATIONS']['c_business_conversations'],
          percentage: data['BUSINESS_CONVERSATIONS']['per_business_conversations']
        },
        websiteVisites: data['WEBSITE_CLICKS']['c_webiste_clicks'],
        directions: data['BUSINESS_DIRECTION_REQUESTS']['c_business_direction_requests'],
        phone: data['CALL_CLICKS']['c_call_clicks'],
        searchReport: data["RAW_DATA"]?.["business_impression_desktop_search"] || [],
        mapReport: data["RAW_DATA"]?.["business_impression_desktop_maps"] || [],
        websiteClickReport: data["RAW_DATA"]?.["webiste_clicks"] || [],
        directionReport: data["RAW_DATA"]?.["business_direction_requests"] || [],
        callClickReport: data["RAW_DATA"]?.["call_clicks"] || [],
        conversationsReport: data["RAW_DATA"]?.["business_conversations"] || [],
      }

    })
  }

  componentDidUpdate(prevProps, prevState) {
    const P_START_DATE = dayjs(prevState.dates[0]).format(dateFormat)
    const P_END_DATE = dayjs(prevState.dates[1]).format(dateFormat)

    const START_DATE = dayjs(this.state.dates[0]).format(dateFormat)
    const END_DATE = dayjs(this.state.dates[1]).format(dateFormat)

    if (START_DATE !== P_START_DATE || END_DATE !== P_END_DATE) {
      console.log("DATE CHANGED")
      this.updateReports();
    }
  }

  componentWillUnmount() {
  }

  setSchedule(scheduleOpen) {
    this.setState({ scheduleOpen })
  }

  Report({
    allows,
    ...props
  }) {
    return (
      <>
        {allows[REPORT.R_Q_SUMMARY] && (
          <Col lg="12">
            <Card className="mini-stats-wid">
              <CardHeader>
                Reviews & Questions Summary
              </CardHeader>
              <CardBody>
                <div className="row">
                  <div className="col-md-4">
                    <div className="d-flex">
                      <div className="flex-grow-1">
                        <p className="text-muted fw-medium">
                          REVIEW COUNT
                        </p>
                        <h4 className="mb-0">
                          {this.state.summary.reviewCount}
                        </h4>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="d-flex">
                      <div className="flex-grow-1">
                        <p className="text-muted fw-medium">
                          AVERAGE RATING
                        </p>
                        <h4 className="mb-0">
                          {this.state.summary.averageRating}
                        </h4>
                        {this.state.summary.averageRating && (
                          <div className="rating-component">
                            <RatingTooltip
                              max={5}
                              style={{ justifyContent: 'left' }}
                              disabled={true}
                              ActiveComponent={
                                <i
                                  key={"active_12"}
                                  className="mdi mdi-star text-primary"
                                  style={{ fontSize: "15px" }}
                                />
                              }
                              InActiveComponent={
                                <i
                                  key={"active_12"}
                                  className="mdi mdi-star-outline text-primary"
                                  style={{ fontSize: "15px" }}
                                />
                              }
                              defaultRating={this.state.summary.averageRating}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="d-flex">
                      <div className="flex-grow-1">
                        <p className="text-muted fw-medium">
                          QUESTIONS COUNT
                        </p>
                        <h4 className="mb-0">
                          {this.state.summary.questions?.length || 0}
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
        )}
        {allows[REPORT.PERFORMANCE_SUMMARY] && (
          <Col lg="12">
            <Card className="mini-stats-wid">
              <CardHeader>
                Performance Summary
              </CardHeader>
              <CardBody>
                <div className="row">
                  <div className="col-md-3">
                    <div className="d-flex">
                      <div className="flex-grow-1">
                        <p className="text-muted fw-medium">
                          Search Impression
                        </p>
                        <h4 className="mb-0">
                          {this.state.summary.searchImpression.value}
                          <span style={{
                            color: (Number(this.state.summary.searchImpression.percentage) > 0) ? 'green' : 'red',
                            paddingLeft: "15px",

                          }}>
                            <i className="zso-arrow_back _up" style={{
                              position: "absolute",
                              transform: `rotate(${(Number(this.state.summary.searchImpression.percentage) > 0) ? '90' : '-90'}deg)`
                            }}></i>
                            {Number(this.state.summary.searchImpression.percentage).toFixed(2)}%
                          </span>
                        </h4>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="d-flex">
                      <div className="flex-grow-1">
                        <p className="text-muted fw-medium">
                          Map Impression
                        </p>
                        <h4 className="mb-0">
                          {this.state.summary.mapImpression.value}
                          <span style={{
                            color: (Number(this.state.summary.mapImpression.percentage) > 0) ? 'green' : 'red',
                            paddingLeft: "15px",

                          }}>
                            <i className="zso-arrow_back _up" style={{
                              position: "absolute",
                              transform: `rotate(${(Number(this.state.summary.mapImpression.percentage) > 0) ? '90' : '-90'}deg)`
                            }}></i>
                            {Number(this.state.summary.mapImpression.percentage).toFixed(2)}%
                          </span>
                        </h4>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="d-flex">
                      <div className="flex-grow-1">
                        <p className="text-muted fw-medium">
                          VISITOR ACTION
                        </p>
                        <div>
                          <ReactApexChart
                            series={[this.state.summary.websiteVisites, this.state.summary.directions, this.state.summary.phone]}
                            options={{
                              labels: ["Website Visits", "Directions", "Phone No"],
                              colors: ["#34c38f", "#556ee6", "#f46a6a"],
                              legend: {
                                show: true,
                                position: "bottom",
                                fontSize: "5px",
                                offsetX: 0,
                                offsetY: -10,
                              },
                              responsive: [
                                {
                                  breakpoint: 600,
                                  options: {
                                    chart: {
                                      height: 240,
                                    },
                                    legend: {
                                      show: false,
                                    },
                                  },
                                },
                              ],
                            }}
                            type="pie"
                            height="240"
                            className="apex-charts"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="d-flex">
                      <div className="flex-grow-1">
                        <p className="text-muted fw-medium">
                          CONVERSATIONS
                        </p>
                        <h4 className="mb-0">
                          {this.state.summary.conversations.value}
                          <span style={{
                            color: (Number(this.state.summary.conversations.percentage) > 0) ? 'green' : 'red',
                            paddingLeft: "15px",

                          }}>
                            <i className="zso-arrow_back _up" style={{
                              position: "absolute",
                              transform: `rotate(${(Number(this.state.summary.conversations.percentage) > 0) ? '90' : '-90'}deg)`
                            }}></i>

                            {Number(this.state.summary.conversations.percentage).toFixed(2)}%
                          </span>
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
        )}
        {allows[REPORT.REVIEW_GROWTH] && (
          <Col lg="6">
            <Card className="mini-stats-wid">
              <CardHeader>
                Review Growth
              </CardHeader>
              <CardBody>
                <div className="d-flex mb-2">
                  <div className="flex-grow-1">
                    Total Review:&nbsp;<span className="font-bold">{" "}{this.state.summary.reviewCount} </span>
                  </div>
                  <div>
                    Average Rating: &nbsp;<span className="font-bold">{" "}{this.state.summary.averageRating} </span>
                  </div>
                </div>
                <ReactApexChart options={{
                  chart: {
                    type: 'bar',
                    height: 350
                  },
                  plotOptions: {
                    bar: {
                      horizontal: false,
                      columnWidth: '55%',
                      endingShape: 'rounded'
                    },
                  },
                  dataLabels: {
                    enabled: false
                  },
                  stroke: {
                    show: true,
                    width: 2,
                    colors: ['transparent']
                  },
                  xaxis: {
                    categories: this.state.summary.reviews.map(i => i.s_date),
                  },
                  yaxis: {
                    title: {
                      text: 'Views'
                    }
                  },
                  fill: {
                    opacity: 1
                  },
                  tooltip: {
                    y: {
                      formatter: function (val) {
                        return "" + val + " views"
                      }
                    }
                  }
                }} series={[{
                  name: 'Net Profit',
                  data: this.state.summary.reviews.map(i => i.total)
                }]} type="bar" height={324} />
              </CardBody>
            </Card>
          </Col>
        )}
        {allows[REPORT.QUESTION_GROWTH] && (
          <Col lg="6">
            <Card className="mini-stats-wid">
              <CardHeader>
                Question Growth
              </CardHeader>
              <CardBody>
                <ReactApexChart options={{
                  chart: {
                    type: 'bar',
                    height: 350
                  },
                  plotOptions: {
                    bar: {
                      horizontal: false,
                      columnWidth: '55%',
                      endingShape: 'rounded'
                    },
                  },
                  dataLabels: {
                    enabled: false
                  },
                  stroke: {
                    show: true,
                    width: 2,
                    colors: ['transparent']
                  },
                  xaxis: {
                    categories: this.state.summary.questions.map(i => i.s_date),
                  },
                  yaxis: {
                    title: {
                      text: 'Count'
                    }
                  },
                  fill: {
                    opacity: 1
                  },
                  tooltip: {
                    y: {
                      formatter: function (val) {
                        return "" + val + " views"
                      }
                    }
                  }
                }} series={[{
                  name: 'Net Profit',
                  data: this.state.summary.questions.map(i => i.total)
                }]} type="bar" height={350} />
              </CardBody>
            </Card>
          </Col>
        )}
        {allows[REPORT.SEARCH] && (
          <Col lg="6">
            <Card className="mini-stats-wid">
              <CardHeader>
                Search Impression
              </CardHeader>
              <CardBody>
                <ColumnChart
                  series={[{
                    name: "Search Impression",
                    data: this.state.summary.searchReport.map(i => i.value)
                  }]}
                  categories={this.state.summary.searchReport.map(i => moment(i.sdate).format('DD MMM'))}
                  xName="views"
                />
              </CardBody>
            </Card>
          </Col>
        )}
        {allows[REPORT.MAP] && (
          <Col lg="6">
            <Card className="mini-stats-wid">
              <CardHeader>
                Map Impression
              </CardHeader>
              <CardBody>
                <ColumnChart
                  series={[{
                    name: "Map Impression",
                    data: this.state.summary.mapReport.map(i => i.value)
                  }]}
                  categories={this.state.summary.mapReport.map(i => moment(i.sdate).format('DD MMM'))}
                  xName="views"
                />
              </CardBody>
            </Card>
          </Col>
        )}
        {allows[REPORT.VISITOR] && (
          <Col lg="12">
            <Card className="mini-stats-wid">
              <CardHeader>
                Visitor Actions
              </CardHeader>
              <CardBody>
                <ColumnChart
                  series={[
                    {
                      name: "Website Clicks",
                      data: this.state.summary.websiteClickReport.map(i => i.value)
                    },
                    {
                      name: "Direction Clicks",
                      data: this.state.summary.directionReport.map(i => i.value)
                    },
                    {
                      name: "Call Clicks",
                      data: this.state.summary.callClickReport.map(i => i.value)
                    },
                  ]}
                  categories={this.state.summary.websiteClickReport.map(i => moment(i.sdate).format('DD MMM'))}
                  xName="views"
                />
              </CardBody>
            </Card>
          </Col>
        )}
        {allows[REPORT.CONVERSATION] && (
          <Col lg="6">
            <Card className="mini-stats-wid">
              <CardHeader>
                CONVERSATIONS
              </CardHeader>
              <CardBody>
                <ColumnChart
                  series={[{
                    name: "Conversations",
                    data: this.state.summary.conversationsReport.map(i => i.value)
                  }]}
                  categories={this.state.summary.conversationsReport.map(i => moment(i.sdate).format('DD MMM'))}
                  xName="views"
                />
              </CardBody>
            </Card>
          </Col>
        )}
        {allows[REPORT.ALL_SUMARY] && (
          <>
            {this.state.reports.map((report, key) => (
              <Col md="4" key={"_col_" + key}>
                <Card className="mini-stats-wid">
                  <CardBody>
                    <div className="d-flex">
                      <div className="flex-grow-1">
                        <p className="text-muted fw-medium">
                          {report.title}
                        </p>
                        <h4 className="mb-0">
                          {report.description}
                          <span style={{
                            color: (Number(report.percentage) > 0) ? 'green' : 'red',
                            paddingLeft: "15px",

                          }}>
                            <i className="zso-arrow_back _up" style={{
                              position: "absolute",
                              transform: `rotate(${(Number(report.percentage) > 0) ? '90' : '-90'}deg)`
                            }}></i>
                            {Number(report.percentage).toFixed(2)}%
                          </span>
                        </h4>
                      </div>
                      <div className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
                        <span className="avatar-title">
                          <i
                            className={
                              "" + report.iconClass + " font-size-24"
                            }
                          />
                        </span>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            ))}
          </>
        )}
      </>
    )
  }

  setOpenKeys(openKeys) {
    this.setState({ openKeys })
  }

  setDates(dates) {
    this.setState({ dates })
  }
  setValue(value) {
    this.setState({ value })
  }

  disabledDate(current) {
    // console.log("tooLate, tooEarly", this, current)
    if (current && current > moment().endOf('day')) {
      return true
    }
    if (!this.state.dates) {
      return false;
    }
    const tooLate = this.state.dates[0] && current.diff(this.state.dates[0], 'days') > 30;
    const tooEarly = this.state.dates[1] && this.state.dates[1].diff(current, 'days') > 30;
    return !!tooEarly || !!tooLate;
  };

  onOpenChange(open) {
    // if (open) {
    //   this.setDates([null, null]);
    // } else {
    //   this.setDates(null);
    // }
  };

  handleConvertToImage() {
    html2canvas(this.state.divRef.current).then(canvas => {
      const imgData = canvas.toDataURL('image/png');
      const downloadLink = this.state.linkRef.current;
      downloadLink.href = imgData;
      downloadLink.download = 'image.png';
      downloadLink.click();
    });
  };

  convertToUppercase(str) {
    return str.slice(0, 2).toUpperCase();
  }

  render() {
    //meta title
    document.title = "Locations";

    return (
      <React.Fragment key={this.props?.match?.params?.id}>
        <Container className="px-3 py-4 print-report" fluid>
          <div className="row mb-3">
            <div className="col-12 print-report-header">
              <div className="d-flex gap-2 align-items-center">
                <Avatar style={{ backgroundColor: "gray", verticalAlign: 'middle', }}>
                  {this.convertToUppercase(this.state?.location?.title || '+')}
                </Avatar>
                <h4 className="mb-0">
                  {this.state?.location?.title}
                </h4>
              </div>
              <div className="d-flex flex-direction-row-reverse mb-4">
                Report generated by Operations on {moment().format("DD MMM YYYY hh:mm A")}
              </div>
            </div>
          </div>
          <Row>
            <div className="col-12 print-report-header-second">
              <div className="d-flex">
                <h3 className="ml-3">Google My Business: {this.state.cardInfo?.name || ""}
                </h3>
                {this.state.cardInfo?.name ? document.body.classList.add("loading_done") : ""}
                &nbsp;
                <div className="date ml-5">
                  <Badge pill bg="primary">
                    {dayjs(this.state.dates[0]).format("MMM YYYY, DD")} - {dayjs(this.state.dates[1]).format("MMM YYYY, DD")}
                  </Badge>
                </div>
              </div>
            </div>
            <Col lg="12">
              <Row>
                <>
                  <this.Report allows={this.state.cards} />
                </>

              </Row>
            </Col>
          </Row>
        </Container>
      </React.Fragment >
    );
  }
}

PdfReports.propTypes = {
  t: PropTypes.any,
};

const mapStateToProps = ({ Location, BasicReport }) => {
  return ({
    locations: Location.locations,
    isLoading: Location.isLoading,
    selectedLocation: Location.selectedLocation,
    isLocationSelected: Location.isSelectedLocation,

    basicReports: BasicReport.list,
  })
};

const mapDispatchToProps = dispatch => ({
  fetchBasicReports: (id) => dispatch(fetchBasicReports(id)),
  selectLocation: (id, history) => dispatch(selectLocation(id, history)),
});

export default (withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(PdfReports))));
