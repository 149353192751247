import { createSlice, createAsyncThunk, current } from '@reduxjs/toolkit';
import { message } from 'antd';
import { history } from '../../App';
import service from '../../helpers/Api/FetchInterceptor';

const API_URL = "/basic_reports"

// Define a thunk function for fetching data
export const fetchBasicReports = createAsyncThunk(
  'basicReport/fetchBasicReports',
  async (id) => {
    let result = await service.get(`${API_URL}?id=${id}&allPage=true`);
    return result.data;
  }
);

// Define a thunk function for creating data
export const fetchSpecificBasicReports = createAsyncThunk(
  'basicReport/fetchSpecificBasicReports',
  async (id) => {
    const result = await service.get(API_URL + "/" + id);
    return result.data;
  }
);
// Define a thunk function for creating data
export const createBasicReport = createAsyncThunk(
  'basicReport/createBasicReport',
  async (card) => {
    let result = await service.post(`/basic_reports`, {
      ...card.data
    });
    return result.data;
  }
);

// Define a thunk function for updating data
export const updateBasicReport = createAsyncThunk(
  'basicReport/updateBasicReport',
  async (updatedBasicReport) => {
    const [id, values] = updatedBasicReport;
    console.log("UPDATE", updatedBasicReport)
    const formData = new FormData();
    Object.keys(values).forEach(key => formData.append(key, values[key]));
    let result = await service({
      url: API_URL + "/" + id,
      method: "POST",
      data: formData
    });
    return result.data;
  }
);

/* export const addCardToReportBasic = createAsyncThunk(
  'basicReport/addCardToReportBasic',
  async (card) => {
    console.log("CARD: ", card, card?.card)
    const pageIndex = card?.pageIndex;

    // console.log("XXXXXXXXXXXXXXXXXXXX");
    let data = await new Promise((resolve) => {
      return resolve({
        pageIndex: pageIndex,
        card: card.card,
        cb: card.cb
      })
    })
    return data;
  }
);
 */
export const addCardToReportBasic = createAsyncThunk(
  'basicReport/addCardToReportBasic',
  async (card) => {
    console.log("CARD: ", card, card?.card)
    const pageIndex = card?.pageIndex;

    const result = await service.patch(`/basic_reports/${card?.patchData.id}`, {
      ...card?.patchData
    });

    return ({
      result: result.data,
      pageIndex: pageIndex,
      card: card.card,
      cb: card.cb
    })
  }
);


export const updateReportScheduleBasic = createAsyncThunk(
  'basicReport/updateReportScheduleBasic',
  async (card) => {
    let result = await service.patch(`/basic_reports/${card.id}`, {
      ...card.data
    });
    return result.data;
  }
)

export const deleteCardToReportBasic = createAsyncThunk(
  'basicReport/deleteCardToReportBasic`',
  async (card) => {
    console.log("CARD: ", card, card?.card)
    const pageIndex = card?.pageIndex;

    const result = await service.patch(`/basic_reports/${card?.patchData.id}`, {
      ...card?.patchData
    });

    return ({
      result: result.data,
      pageIndex: pageIndex,
      card: card.card,
      cb: card.cb
    })
  }
);

// Define a thunk function for deleting data
export const deleteBasicReport = createAsyncThunk(
  'basicReport/deleteBasicReport',
  async (id) => {
    await service.delete(`/basic_reports/${id}`);
    return id;
  }
);

// Define a slice with initial state and reducers
const basicReportSlice = createSlice({
  name: 'basicReport',
  initialState: {
    single: {},
    current: {},
    list: [],
    pagination: {
      pageSize: 5,
      current: 0,
      total: 0,
    },
    isLoading: false,
    isLoadingSchedule: false,
    isLoadingCardUpdate: false,
    isDeleteLoading: false,
    isCreateLoading: false,
    sort: {},
    error: null,
  },
  reducers: {
    selectBasicReport(state, action) {
      const sub1 = "Summary";
      const sub2 = "Review";
      const sub3 = "Performance"
      const keyMap = {
        sub1,
        sub2,
        sub3
      }
      console.log(action.payload, keyMap[action.payload] )
      const index = state.list.findIndex(i => i.name == keyMap[action.payload])
      if (index == -1) {
        state.error = "Selected Report not found";
        return;
      }
      state.current = state.list[index];
    },
  },
  extraReducers: (builder) => {
    // Add reducers for fetching data
    builder
      .addCase(fetchBasicReports.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchBasicReports.fulfilled, (state, action) => {
        state.isLoading = false;
        const data = action.payload
        state.list = data;
      })
      .addCase(fetchBasicReports.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      });

    // Add reducers for fetching data
    builder
      .addCase(fetchSpecificBasicReports.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchSpecificBasicReports.fulfilled, (state, action) => {
        state.isLoading = false;
        const data = action.payload
        state.single = data?.exibitor
      })
      .addCase(fetchSpecificBasicReports.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      });

    // Add reducers for creating data
    builder
      .addCase(createBasicReport.pending, (state) => {
        state.isCreateLoading = true;
        state.error = null;
      })
      .addCase(createBasicReport.fulfilled, (state, action) => {
        state.isCreateLoading = false;
        state.list = [action.payload.basic_report, ...state.list];
        state.current = action.payload.basic_report
      })
      .addCase(createBasicReport.rejected, (state, action) => {
        state.isCreateLoading = false;
        state.error = action.error.message;
      });

    builder
      .addCase(addCardToReportBasic.pending, (state) => {
        state.isLoadingCardUpdate = true;
        state.error = null;
      })
      .addCase(addCardToReportBasic.fulfilled, (state, action) => {
        state.isLoadingCardUpdate = false;
        console.log("ACTION:", action)
        state.list[action.payload.pageIndex].cards = [...state.list[action.payload.pageIndex].cards, { name: action.payload.card.key }]
        action.payload?.cb();
      })
      .addCase(addCardToReportBasic.rejected, (state, action) => {
        state.isLoadingCardUpdate = false;
        state.error = action.error.message;
      });

    builder
      .addCase(deleteCardToReportBasic.pending, (state) => {
        state.isLoadingCardUpdate = true;
        state.error = null;
      })
      .addCase(deleteCardToReportBasic.fulfilled, (state, action) => {
        state.isLoadingCardUpdate = false;
        console.log("ACTION:", action)
        state.list[action.payload.pageIndex].cards = state.list[action.payload.pageIndex].cards.filter(i => i.name != action.payload.card.key)
        action.payload?.cb();
      })
      .addCase(deleteCardToReportBasic.rejected, (state, action) => {
        state.isLoadingCardUpdate = false;
        state.error = action.error.message;
      });

    // Add reducers for updating data
    builder
      .addCase(updateBasicReport.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(updateBasicReport.fulfilled, (state, action) => {
        state.isLoading = false;
        const index = state.list.findIndex(exibitor => exibitor.id === action.payload.id);
        if (index !== -1) {
          state.list[index] = action.payload;
        }
        history.push("/basicReport")
      })
      .addCase(updateBasicReport.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      });

    builder
      .addCase(updateReportScheduleBasic.pending, (state) => {
        state.isLoadingSchedule = true;
        state.error = null;
      })
      .addCase(updateReportScheduleBasic.fulfilled, (state, action) => {
        state.isLoadingSchedule = false;
        const index = state.list.findIndex(exibitor => exibitor.id === action.payload.basic_report.id);
        if (index !== -1) {
          state.list[index] = action.payload.basic_report;
          state.current = action.payload.basic_report
        }
      })
      .addCase(updateReportScheduleBasic.rejected, (state, action) => {
        state.isLoadingSchedule = false;
        state.error = action.error.message;
      });

    builder
      .addCase(deleteBasicReport.pending, (state) => {
        state.isDeleteLoading = true;
        state.error = null;
      })
      .addCase(deleteBasicReport.fulfilled, (state, action) => {
        state.isDeleteLoading = false;
        state.list = state.list.filter(exibitor => exibitor.id !== action.payload);
        state.current = {};
      })
      .addCase(deleteBasicReport.rejected, (state, action) => {
        state.isDeleteLoading = false;
        state.error = action.error.message;
      });
  }
})

// Export the action creators and the reducer
export const { selectBasicReport } = basicReportSlice.actions;
export default basicReportSlice.reducer;
