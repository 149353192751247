import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import ReactApexChart from "react-apexcharts";

import EMPTY_IMG from "../../../assets/images/reports-empty-widget.svg"

function isEmpty(array) {
  let empty = true;
  array.map(i => {
    i.data.map(j => {
      if (j) {
        empty = false
      }
    })
  })

  return empty;
}

function ColumnChart({
  categories = [],
  series = [],
  xName = "",
  yName = ""
}) {

  let updatedSeries = []
  let updatedCategories = []

  useEffect(() => {
    console.log(series, categories, "SERIES")
    updatedSeries = series.map(i => {
      return {
        ...i,
        data: i.data.filter((j, index) => {
          if (j) {
            // updatedCategories.push(categories[index]);
            return j
          }
          return false
        })
      }
    })
  }, [series])

  return (
    <>
      {console.log(isEmpty(series), "EMPTY")}

      {isEmpty(series) ? (
        <>
          <div className="empty-chart">
            <img src={EMPTY_IMG}  />
            <div className="greyTxt">There is no data available currently for this report.</div>
          </div>
        </>
      ) : (
        <ReactApexChart options={{
          chart: {
            type: 'bar',
            height: 350
          },
          plotOptions: {
            bar: {
              horizontal: false,
              columnWidth: '55%',
              endingShape: 'rounded'
            },
          },
          dataLabels: {
            enabled: false
          },
          stroke: {
            show: true,
            width: 2,
            colors: ['transparent']
          },
          xaxis: {
            categories: categories,
            text: xName
          },
          yaxis: {
            title: {
              text: yName
            }
          },
          fill: {
            opacity: 1
          },
          tooltip: {
            y: {
              formatter: function (val) {
                return "" + val
              }
            }
          }
        }} series={series} type="bar" height={350} />
      )}
    </>
  )
}

ColumnChart.propTypes = {
  series: PropTypes.array,
  categories: PropTypes.array,
  xName: PropTypes.string,
  yName: PropTypes.string,
}

export default ColumnChart
