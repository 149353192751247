
// Import scss
// import "./assets/scss/theme.scss";
import React from "react"
import ReactDOM from "react-dom/client"


import App from "./App"
import * as serviceWorker from "./serviceWorker"
import { BrowserRouter } from "react-router-dom"
import "./i18n"
import { Provider } from "react-redux"

import store from "./store";
import { GoogleOAuthProvider } from "@react-oauth/google"


const CLIENT_ID = "476931944629-rksefil5mr0li8c58ajv78pc7t6u84o5.apps.googleusercontent.com";


ReactDOM.createRoot(document.getElementById('root')).render(
  <React.Fragment>
    <GoogleOAuthProvider clientId={CLIENT_ID} >
      <Provider store={store}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </Provider>
    </GoogleOAuthProvider>
  </React.Fragment>
);

serviceWorker.unregister();