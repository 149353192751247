import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux';
import { Container } from 'reactstrap';
import { getLocations } from '../../store/actions';
import { withRouter } from "react-router";

import { LoadingOutlined } from '@ant-design/icons';



class BasicWrapperToHandleLocations extends Component {
  static propTypes = {}

  constructor(props) {
    super(props);
    this.state = {
      key: Math.random()
    }
  }

  rerender() {
    this.setState({ key: Math.random() })
  }

  componentDidMount() {
    if (!this.props.locations.length)
      this.props.getLocations({ t: 1 }, this.props.history)
  }

  render() {
    return (
      <>
        {!this.props.isLoading && !this.props.locations.length && (
          <div className="page-content">
            <Container fluid className='mt-5'>
              <h5 className='text-center'>
                Please Add locations first
              </h5>
            </Container>
          </div>
        )}
        {!!this.props.isLoading && (
          <div className="page-content">
            <Container fluid>
              <div className="d-flex justify-content-center overflow-hidden mt-3">
                <LoadingOutlined style={{
                  fontSize: "25px",
                }} />
              </div>
            </Container>
          </div>
        )}
        {!!(!this.props.isLoading && this.props.locations.length) && (
          <>
            {this.props.children}
          </>
        )}

      </>
    )
  }
}

const mapStateToProps = ({ Location }) => {
  return ({
    locations: Location.locations,
    isLoading: Location.isLoading
  })
};

const mapDispatchToProps = dispatch => ({
  getLocations: (pay, his) => dispatch(getLocations(pay, his)),
});


export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(BasicWrapperToHandleLocations)) 