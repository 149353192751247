import { useState } from "react";
// import GoogleLogin, { GoogleLogout, GoogleLoginResponse, GoogleLoginResponseOffline } from "react-google-login";
import { GoogleLogin, useGoogleLogin, GoogleOAuthProvider } from '@react-oauth/google';
import { Button, Col, Container, Row } from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import axios from "axios";
import GoogleLoginModal from "../../components/Common/GoogleLoginModal";


const GoogleLoginComponent = () => {
  return (
    <>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title={("Dashboards")}
            breadcrumbItem={("Notifications")}
          />
        </Container>

      </div>


    </>
  );
};

export default GoogleLoginComponent