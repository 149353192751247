import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import { ADD_WEBINAR, WEBINAR_TEST, DELETE_WEBINAR, GET_WEBINARS, GET_SPECIFIC_WEBINAR, UPDATE_WEBINAR } from "./actionTypes"
import { getWebinarSuccess, getWebinarFail, updateWebinarLoading1, updateWebinarSuccess, deleteWebinarSuccess } from "./actions"
import service from "../../helpers/Api/FetchInterceptor"

//Include Both Helper File with needed methods
function getWebinars(params) {
  return async function () {
    let result = await service.get('/webinars' + "?" + params);
    return result.data;
  }
}
function getWebinar(id) {
  return async function () {
    let result = await service.get('/webinars/' + id);
    return result.data;
  }
}

function putWebinar(webinar) {
  return async function () {
    const formData = new FormData();
    Object.keys(webinar).forEach(key => formData.append(key, object[key]));
    let result = await service.put('/webinars', {
      data: formData
    });
    return result.data;
  }
}

function* fetchWebinar({
  payload: params
}) {
  try {
    const response = yield call(getWebinars(params))
    yield put(getWebinarSuccess(response))
  } catch (error) {
    yield put(getWebinarFail(error))
  }
}

function* addWebinar({
  payload
}) {
  try {
    yield put(updateWebinarLoading1(true))
    const response = yield call(() => payload)
    yield put(updateWebinarSuccess(response.data.webinar))
  } catch (error) {
    yield put(getWebinarFail(error))
  } finally {
    yield put(updateWebinarLoading1(false))
  }
}

function* updateWebinar({
  payload
}) {
  try {
    yield put(updateWebinarLoading1(true))
    const response = yield call(() => payload)
    yield put(updateWebinarSuccess(response.data.webinar))
  } catch (error) {
    yield put(getWebinarFail(error))
  } finally {
    yield put(updateWebinarLoading1(false))
  }
}

function* fetchWebinarWithId({
  payload
}) {
  try {
    yield put(updateWebinarLoading1(true))
    const response = yield call(() => payload)
    yield put(updateWebinarSuccess(response.data.webinar))
  } catch (error) {
    yield put(getWebinarFail(error))
  } finally {
    yield put(updateWebinarLoading1(false))
  }
}

function* deleteWebinar({
  payload
}) {
  try {
    yield put(updateWebinarLoading1(true))
    const response = yield call(() => payload)
    console.log('called', response)
    yield put(deleteWebinarSuccess(response.data.webinar))
  } catch (error) {
    yield put(getWebinarFail(error))
  } finally {
    yield put(updateWebinarLoading1(false))
  }
}

function* fetchTest({
  payload
}) {
  try {
    yield put(updateWebinarLoading1(true))
    const response = yield call(() => payload)
  } catch (error) {
    yield put(getWebinarFail(error))
  } finally {
    yield put(updateWebinarLoading1(false))
  }
}

function* webinarsSaga() {
  yield takeEvery(GET_WEBINARS, fetchWebinar)
  yield takeEvery(ADD_WEBINAR, addWebinar)
  yield takeEvery(UPDATE_WEBINAR, updateWebinar)
  yield takeEvery(GET_SPECIFIC_WEBINAR, fetchWebinarWithId)
  yield takeEvery(DELETE_WEBINAR, deleteWebinar)
  yield takeEvery(WEBINAR_TEST, fetchTest)
}

export default webinarsSaga
